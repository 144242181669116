import React, { useEffect, useState } from 'react'

import { Button, RangeInput } from '../../../../common/components'
import { FormBlock, Title, Text } from '../../pages/Config/styles'

interface RadiusProps {
  initialRadius: number
  onChange: (radius: number) => Promise<void>
}

export const DeliveryRadiusForm: React.FC<RadiusProps> = ({
  initialRadius,
  onChange
}) => {
  const [radius, setRadius] = useState<number>(initialRadius)
  const [hasChanges, setChanges] = useState<boolean>(false)

  const handleUpdateRadius = (value: number) => {
    setRadius(value)
  }

  const handlePostRadius = () => {
    onChange(radius)
  }

  const handleCheckForChanges = () => {
    if (radius === initialRadius) {
      setChanges(false)
    } else {
      setChanges(true)
    }
  }

  useEffect(() => {
    handleCheckForChanges()
  }, [initialRadius, radius])

  useEffect(() => {
    setRadius(initialRadius)
  }, [initialRadius])

  return (
    <FormBlock>
      <Title>Definir raio de entrega</Title>
      <RangeInput
        value={radius}
        onChange={handleUpdateRadius}
        step={1}
        max={60}
      />

      <Text>{`${radius} km`}</Text>

      <Button
        primary
        type="button"
        onClick={handlePostRadius}
        disabled={!hasChanges}
      >
        Definir novo raio de entrega
      </Button>
    </FormBlock>
  )
}
