import styled from 'styled-components'

export const PrivatePage = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: 14.375rem 1fr;

  background: ${({ theme }) => theme.colors.body};
`

export const ContentPage = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 2.5rem 0 1.25rem;
`

export const ContentSection = styled.section`
  margin: 0 auto;
  width: 87.5%;
  height: 100%;

  display: grid;
  grid-template-rows: 2.625rem 1fr;

  @media (min-width: 85.375rem) {
    width: 100%;
    max-width: 59.25rem;
  }

  @media (min-width: 97.5rem) {
    max-width: 71.25rem;
  }
`

export const ContentWrapper = styled.div`
  padding: 3rem 0 0;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
`
