import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.inputs.labelFontSize};
  font-weight: ${({ theme }) => theme.inputs.labelFontWeight};
  color: ${({ theme }) => theme.colors.input.label};
  margin-bottom: 0.5rem;
`
