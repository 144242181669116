import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  column-gap: 1rem;
`

export const ErrorText = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.error};
  padding: 0.5rem 0;
`
