import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
  Button,
  TagField,
  FormWrapper,
  FormRow,
  FormColumn,
  Toast
} from '../../../../common/components'
import { PageContainer } from '../../../../layout'
import { ProgressSteps } from '../../components'

import { NewProductContext } from '../../../../context/AddProductContext'
import { useGetCategories } from '../../../../hooks/useGetCategories'

import { Content, Box, UpperContent, Footer, Form, Title } from './styles'
import { CategoryInterface } from '../../../../api/interfaces'

const AddProductSecondStep: React.FC = () => {
  const history = useHistory()
  const { value, setValue } = useContext(NewProductContext)

  const { data: categories, loading: loadingCategories } = useGetCategories()

  const validationSchema = yup.object().shape({
    categories: yup
      .array()
      .min(1, 'Ao menos uma categoria deve ser selecionada')
  })

  const {
    isValid,
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validateForm,
    submitForm
  } = useFormik({
    initialValues: value.step2,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: productValues => {
      const step2 = {
        categories: productValues.categories
      }

      setValue(current => ({ ...current, step2: step2 }))
      history.push('/product/new/step3')
    }
  })

  const goBack = () => {
    history.push('/product/new/step1')
  }

  const onChangeCategories = (value: CategoryInterface[]) => {
    setFieldValue('categories', value)
    setFieldTouched('categories', true, false)
    validateForm({
      ...values,
      categories: value
    })
  }

  useEffect(() => {
    if (!value.step1.name || !value.step1.desc) {
      history.push('/product/new/step1')
    }
  }, [])

  return (
    <PageContainer title="Adicionar produto" urlGroup="products">
      <Content>
        <Box>
          {loadingCategories ? (
            <div style={{ padding: '1.25rem' }}>
              <Toast loading />
            </div>
          ) : (
            <>
              <UpperContent>
                <ProgressSteps currentStep={2} numberOfSteps={4} />
                <Title>Adicione as categorias</Title>
                <Form onSubmit={handleSubmit}>
                  <FormWrapper>
                    <FormRow>
                      <FormColumn>
                        <TagField
                          label="Categorias"
                          error={
                            errors.categories && touched.categories
                              ? errors.categories.toString()
                              : ''
                          }
                          value={values.categories}
                          onChange={onChangeCategories}
                          optionList={categories || []}
                        />
                      </FormColumn>
                    </FormRow>
                  </FormWrapper>
                </Form>
              </UpperContent>
              <Footer>
                <Button type="button" onClick={goBack}>
                  Voltar
                </Button>
                <Button
                  type="button"
                  primary
                  disabled={!isValid}
                  onClick={submitForm}
                >
                  Próximo
                </Button>
              </Footer>
            </>
          )}
        </Box>
      </Content>
    </PageContainer>
  )
}

export default AddProductSecondStep
