import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
  Button,
  FormWrapper,
  FormRow,
  FormColumn,
  ImageUploader
} from '../../../../common/components'
import { PageContainer } from '../../../../layout'
import { ProgressSteps } from '../../components'

import { NewProductContext } from '../../../../context/AddProductContext'

import { Content, Box, UpperContent, Footer, Form, Title } from './styles'

const AddProductThirdStep: React.FC = () => {
  const history = useHistory()
  const { value, setValue } = useContext(NewProductContext)

  const validationSchema = yup.object().shape({
    image: yup.string().required('Esse campo é obrigatório')
  })

  const {
    isValid,
    values,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validateForm,
    submitForm
  } = useFormik({
    initialValues: value.step3,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: productValues => {
      const step3 = {
        image: productValues.image
      }

      setValue(current => ({ ...current, step3: step3 }))
      history.push('/product/new/step4')
    }
  })

  const goBack = () => {
    history.push('/product/new/step2')
  }

  const onChangeImage = (value: string) => {
    setFieldValue('image', value)
    setFieldTouched('image', true, false)
    validateForm({
      ...values,
      image: value
    })
  }

  useEffect(() => {
    if (!value.step2.categories.length) {
      history.push('/product/new/step2')
    }
  }, [])

  return (
    <PageContainer title="Adicionar produto" urlGroup="products">
      <Content>
        <Box>
          <UpperContent>
            <ProgressSteps currentStep={3} numberOfSteps={4} />
            <Title>Adicione uma foto do produto</Title>
            <Form onSubmit={handleSubmit}>
              <FormWrapper>
                <FormRow>
                  <FormColumn>
                    <ImageUploader
                      label="Imagem"
                      value={values.image}
                      onChange={onChangeImage}
                    />
                  </FormColumn>
                </FormRow>
              </FormWrapper>
            </Form>
          </UpperContent>
          <Footer>
            <Button type="button" onClick={goBack}>
              Voltar
            </Button>
            <Button
              type="button"
              primary
              disabled={!isValid}
              onClick={submitForm}
            >
              Próximo
            </Button>
          </Footer>
        </Box>
      </Content>
    </PageContainer>
  )
}

export default AddProductThirdStep
