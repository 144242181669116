import React, { useEffect, useState } from 'react'

import { AnimatedDot, Container } from './styles'
import { ReactComponent as BellIcon } from './assets/bell.svg'
import { useGetOrders } from '../../hooks/useGetOrders'
import { useHistory } from 'react-router-dom'

export const NotificationButton: React.FC = () => {
  const history = useHistory()
  const [hasNotification, setNotification] = useState<boolean>(false)
  const [count, setCount] = useState<number | null>(null)
  const { data, refetch } = useGetOrders()

  const checkNewOrder = () => {
    const newItems = data?.filter(item => item.status[0].idStatus === 1) || null
    if (newItems?.length) {
      setNotification(true)
      setCount(newItems.length)
    } else {
      setNotification(false)
      setCount(null)
    }
  }

  const openOrderList = () => {
    history.push({
      pathname: '/orders',
      state: { orderTab: 'awaiting' }
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 15000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    checkNewOrder()
  }, [data])

  return (
    <Container onClick={openOrderList}>
      <BellIcon />
      {hasNotification && (
        <AnimatedDot>{count && count > 9 ? '9+' : count}</AnimatedDot>
      )}
    </Container>
  )
}
