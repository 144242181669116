import React from 'react'
import { Container, Row, Column, Footer } from './styles'

interface FormProps {
  columns?: string
  children?: React.ReactNode | React.ReactNode[]
  alignCenter?: boolean
  alignRight?: boolean
}

export const FormWrapper: React.FC<FormProps> = ({ children }) => {
  return <Container>{children}</Container>
}

export const FormRow: React.FC<FormProps> = ({ columns, children }) => {
  return <Row columns={columns}>{children}</Row>
}

export const FormColumn: React.FC<FormProps> = ({ children }) => {
  return <Column>{children}</Column>
}

export const FormFooter: React.FC<FormProps> = ({
  children,
  alignCenter = false,
  alignRight = false
}) => {
  return (
    <Footer alignCenter={alignCenter} alignRight={alignRight}>
      {children}
    </Footer>
  )
}
