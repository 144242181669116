import React from 'react'
import { formatCurrency } from '../../../../utils/format'
import { CardContainer, Name, Picture, Price } from './styles'

interface ProductCardProps {
  name: string
  image: string
  price: number
  onClick?: () => void
}

export const ProductCard: React.FC<ProductCardProps> = ({
  name,
  image,
  price,
  onClick
}) => {
  return (
    <CardContainer onClick={onClick}>
      <Picture src={image} alt={name} />
      <Name>{name}</Name>
      <Price>{formatCurrency(price)}</Price>
    </CardContainer>
  )
}
