import React, { useState } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import { useTheme } from 'styled-components'

import { Button, CloseButton } from '../../../../common/components'
import { Title, Text } from '../../pages/Config/styles'
import { AlertTriangle, MapPin } from './Icons'
import {
  FieldWrapper,
  Dropdown,
  DropdownItem,
  Overlay,
  DialogBox,
  Row,
  TextValue
} from './styles'

interface StoreLocation {
  address: string
  lat: number
  lng: number
  onChange: (address: string, lat: number, lng: number) => Promise<void>
}

export const PlacesField: React.FC<StoreLocation> = ({
  address,
  lat,
  lng,
  onChange
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const [addressText, setAddressText] = useState<string>('')
  const [coordinates, setCoordinates] = useState<{
    lat: number | null
    lng: number | null
  }>({ lat: null, lng: null })

  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value)
    const latLng = await getLatLng(results[0])

    setCoordinates(latLng)
    setAddressText(value)
  }

  return (
    <>
      <div>
        <Title>Endereço da loja</Title>
        <Text>Ponto a partir de onde o raio de entrega é calculado</Text>
        <Row>
          {!address || !lat || !lng ? (
            <>
              <AlertTriangle fill={theme.colors.error} />
              <TextValue>Nenhum endereço cadastrado</TextValue>
            </>
          ) : (
            <>
              <MapPin fill={theme.colors.range.thumb} />
              <TextValue>{address}</TextValue>
            </>
          )}
        </Row>
        <Button primary type="button" onClick={() => setOpen(true)}>
          {!address || !lat || !lng ? 'Cadastrar endereço' : 'Mudar endereço'}
        </Button>
      </div>
      {open && (
        <>
          <Overlay onClick={() => setOpen(false)} />
          <DialogBox>
            <CloseButton onClick={() => setOpen(false)} />
            <Title>Novo endereço</Title>
            <Text>Selecione abaixo um novo endereço</Text>
            <PlacesAutocomplete
              value={addressText}
              onChange={setAddressText}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <FieldWrapper>
                  <input
                    {...getInputProps({ placeholder: 'Endereço da loja' })}
                  />
                  {loading ? <Dropdown>loading...</Dropdown> : null}
                  {!loading && !!suggestions.length && (
                    <Dropdown>
                      {suggestions.map(suggestion => (
                        <DropdownItem
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.id}
                        >
                          {suggestion.description}
                        </DropdownItem>
                      ))}
                    </Dropdown>
                  )}
                </FieldWrapper>
              )}
            </PlacesAutocomplete>
            <Button
              primary
              disabled={!addressText || !coordinates.lat || !coordinates.lng}
              onClick={() =>
                onChange(
                  addressText,
                  coordinates.lat as number,
                  coordinates.lng as number
                )
              }
            >
              Salvar
            </Button>
          </DialogBox>
        </>
      )}
    </>
  )
}
