import styled, { css } from 'styled-components'

interface StepProps {
  currentlySelected?: boolean
  finished?: boolean
}

export const Container = styled.div`
  width: fit-content;
  height: 3rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Step = styled.div`
  position: relative;
  flex-shrink: 0;

  width: 3rem;
  height: 3rem;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  font-weight: 600;

  background: ${({ theme }) => theme.colors.progress.background};
  color: ${({ theme }) => theme.colors.progress.font};

  &:not(:first-child) {
    margin-left: 4rem;

    &::before {
      content: '';
      display: block;

      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);

      width: 4rem;
      height: 0.25rem;

      background: ${({ theme }) => theme.colors.progress.background};
    }
  }

  ${({ currentlySelected }: StepProps) =>
    currentlySelected &&
    css`
      border: ${({ theme }) => theme.colors.progress.borderActive};
      background: ${({ theme }) => theme.colors.progress.backgroundActive};

      &:not(:first-child) {
        &::before {
          right: calc(100% + 4px);
          background: ${({ theme }) =>
            theme.colors.progress.backgroundFinished};
        }
      }
    `}

  ${({ finished }: StepProps) =>
    finished &&
    css`
      border: ${({ theme }) => theme.colors.progress.borderFinished};
      background: ${({ theme }) => theme.colors.progress.backgroundFinished};
      color: ${({ theme }) => theme.colors.progress.fontFinished};

      &:not(:first-child) {
        &::before {
          background: ${({ theme }) =>
            theme.colors.progress.backgroundFinished};
        }
      }
    `}
`
