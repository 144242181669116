import React from 'react'
import { Button } from '../../../../common/components'
import { Title, Text, FormBlock } from '../../pages/Config/styles'

interface ToggleStoreProps {
  isOpen: boolean
  toggleStore: (isOpen: boolean) => Promise<void>
}

export const ToggleStoreForm: React.FC<ToggleStoreProps> = ({
  isOpen,
  toggleStore
}) => {
  return (
    <FormBlock noValidate>
      <Title>Funcionamento da loja</Title>
      <Text>
        Habilita e desabilita a disponibilidade do serviço no aplicativo móvel
      </Text>
      <Button primary type="button" onClick={() => toggleStore(isOpen)}>
        {isOpen ? 'Fechar loja' : 'Abrir loja'}
      </Button>
    </FormBlock>
  )
}
