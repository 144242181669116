import React from 'react'
import { useHistory } from 'react-router-dom'

import { List, Item } from './styles'

import { ReactComponent as HomeIcon } from './assets/home.svg'
import { ReactComponent as ProductsIcon } from './assets/products.svg'
import { ReactComponent as OrdersIcon } from './assets/orders.svg'
import { ReactComponent as ProfileIcon } from './assets/profile.svg'
import { ReactComponent as ConfigIcon } from './assets/config.svg'

interface MenuProps {
  currentPath: string
}

export const Menu: React.FC<MenuProps> = ({ currentPath }) => {
  const history = useHistory()

  const canSee = (): boolean => {
    const usr = localStorage.getItem('@manie-delivery:user')
    if (usr) {
      const { adm } = JSON.parse(usr)
      if (adm) return true
    }
    return false
  }

  function followPath(path: string) {
    history.push(path)
  }

  return (
    <List>
      <Item
        onClick={() => followPath('/dashboard')}
        isActive={currentPath === 'dashboard'}
      >
        <HomeIcon />
        <span>Dashboard</span>
      </Item>
      <Item
        onClick={() => followPath('/product/list')}
        isActive={currentPath === 'products'}
      >
        <ProductsIcon />
        <span>Produtos</span>
      </Item>
      <Item
        onClick={() => followPath('/orders')}
        isActive={currentPath === 'orders'}
      >
        <OrdersIcon />
        <span>Pedidos</span>
      </Item>
      {canSee() && (
        <Item
          onClick={() => followPath('/profile')}
          isActive={currentPath === 'profile'}
        >
          <ProfileIcon />
          <span>Perfil</span>
        </Item>
      )}
      <Item
        onClick={() => followPath('/config')}
        isActive={currentPath === 'config'}
      >
        <ConfigIcon />
        <span>Configurações</span>
      </Item>
    </List>
  )
}
