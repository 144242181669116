import React from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'

import { Toast } from '../../../../common/components'
import { useGetLogger } from '../../hooks/useGetLogger'
import {
  Container,
  Title,
  List,
  ItemContainer,
  ItemText,
  Username,
  Time
} from './styles'

export const LogTable: React.FC = () => {
  const { data, loading } = useGetLogger()

  return (
    <Container isLoading={loading}>
      {loading && <Toast loading />}
      {!!data && !loading && (
        <>
          <Title>Últimas atividades</Title>
          <List>
            {data.map(item => (
              <ItemContainer key={item.idLogger}>
                <ItemText>
                  <Username>{`${item.name} ${item.lastName}`}</Username>{' '}
                  {item.action}
                </ItemText>
                <Time>{moment(item.updatedAt).format('LLL')}</Time>
              </ItemContainer>
            ))}
          </List>
        </>
      )}
    </Container>
  )
}
