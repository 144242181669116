export const light = {
  title: 'light',
  font: {
    body: '"Encode Sans", Roboto, Arial, sans-serif',
    headings: 'Poppins, Roboto, Arial, sans-serif'
  },
  buttons: {
    height: '2.625rem',
    padding: '0 1.25rem',
    fontSize: '0.875rem',
    radius: '0.25rem'
  },
  inputs: {
    labelFontSize: '0.875rem',
    labelFontWeight: '400',
    height: '2.625rem',
    borderRadius: '0.25rem',
    border: 'solid 0.0625rem',
    padding: '0 1rem',
    fontSize: '0.875rem',
    fontWeight: '400'
  },
  colors: {
    primary: {
      base: '#1e2327',
      lighten: '#31373b',
      lightest: '#444b50',
      darken: '#111112'
    },
    secondary: {
      base: '#ffd800',
      lighten: '#ffe44e',
      lightest: '#ffed88',
      darken: '#debd03'
    },
    body: 'linear-gradient(to bottom, #f6fbfc, #e7f2f5)',
    blockBackground: '#ffffff',
    blockBorder: 'none',
    shadow: '0px 1px 16px rgba(154, 154, 191, 0.18)',
    error: '#f4364c',
    overlay: 'rgba(48, 54, 61, 0.60)',
    scrollbar: {
      thumb: '#d0d6e1',
      track: '#eaf6fb'
    },
    uploader: {
      border: '#a7bbd2',
      borderAccepted: '#7bb811',
      borderRejected: '#ff5656',
      activeLink: '#0e9f9a'
    },
    font: {
      primary: '#1e2327',
      secondary: '#7d878f'
    },
    sidebar: {
      background: '#ffffff',
      border: 'none'
    },
    button: {
      primaryFont: '#ffffff',
      primaryFontDisabled: '#9d9d9d',
      primaryBackgroundDisabled: '#bfbfbf',

      secondaryFont: '#1e2327',
      secondaryFontDisabled: '#b9b9b9',
      secondaryBackground: '#efefef',
      secondaryBackgroundHover: '#dcdcdc',
      secondaryBackgroundActive: '#c2c2c2',
      secondaryBackgroundDisabled: '#efefef'
    },
    input: {
      label: '#656565',
      border: '#c7c7c7',
      background: '#ffffff',
      placeholder: '',
      value: '#252731',
      borderFocus: '#919191',
      outlineFocus: '#d8d8d8',
      arrowTrack: '#f2f1ec',
      arrows: '#d1d1d1',
      icon: '#656565',
      dropdownItemBackground: '#ffffff',
      dropdownItemFont: '#7E8891',
      dropdownItemBackgroundHover: '#eaeaea',
      activeDropdownItemBackground: '#F0ECD1',
      activeDropdownItemFont: '#BCA002',
      activeDropdownItemBackgroundHover: '#EAE3B2'
    },
    profile: {
      avatarBackground: '#2b2b2b',
      avatarIcon: '#ffd800',
      username: '#2b2b2b',
      occupation: '#707070'
    },
    sidemenu: {
      font: '#7d878f',
      fontActive: '#1e2327',
      backgroundActive: 'linear-gradient(to left, #f6f6f6, #ffffff)',
      hightlightActive: 'solid 0.25rem #ffd800'
    },
    table: {
      header: '#7d878f',
      headerActive: '#1e2327',
      border: 'solid 0.0625rem #dadfe4'
    },
    orderStates: {
      waiting: '#aea306',
      preparing: '#6bae06',
      delivering: '#067aae',
      delivered: '#00cc1b',
      canceled: '#d82f2f'
    },
    range: {
      track: '#e2e3e3',
      thumb: '#000000',
      selected: '#debd03'
    },
    themeChanger: {
      track: '#d4e8e8',
      thumb: '#ffffff',
      moon: '#959fac',
      sun: '#debd03'
    },
    toolbarButton: {
      background: '#ffffff',
      icon: '#444b50'
    },
    progress: {
      font: '#000000',
      fontFinished: '#000000',
      background: '#f2f1eb',
      borderActive: 'solid 0.25rem #ffd800',
      backgroundActive: '#ffffff',
      backgroundFinished: '#ffd800',
      borderFinished: 'none'
    },
    logger: {
      border: '#DFBB05',
      username: '#000000',
      text: '#707070'
    },
    tooltip: {
      background: 'linear-gradient(to bottom, #F7FAFC, #EFEFEF)',
      label: '#444B50',
      value: '#BAA217'
    }
  }
}

export const dark = {
  title: 'dark',
  font: {
    body: '"Encode Sans", Roboto, Arial, sans-serif',
    headings: 'Poppins, Roboto, Arial, sans-serif'
  },
  buttons: {
    height: '2.625rem',
    padding: '0 1.25rem',
    fontSize: '0.875rem',
    radius: '0.25rem'
  },
  inputs: {
    labelFontSize: '0.875rem',
    labelFontWeight: '400',
    height: '2.625rem',
    borderRadius: '0.25rem',
    border: 'solid 0.0625rem',
    padding: '0 1rem',
    fontSize: '0.875rem',
    fontWeight: '400'
  },
  colors: {
    primary: {
      base: '#ffd800',
      lighten: '#ffe44e',
      lightest: '#ffed88',
      darken: '#debd03'
    },
    secondary: {
      base: '#1e2327',
      lighten: '#31373b',
      lightest: '#444b50',
      darken: '#111112'
    },
    body: '#1e2327',
    blockBackground: '#32373b',
    blockBorder: 'solid 1px #434a50',
    shadow: 'none',
    error: '#f4364c',
    overlay: 'rgba(0, 0, 0, 0.85)',
    scrollbar: {
      thumb: '#000000',
      track: '#27292c'
    },
    uploader: {
      border: '#a7bbd2',
      borderAccepted: '#528d10',
      borderRejected: '#fc4848',
      activeLink: '#30dcd1'
    },
    font: {
      primary: '#ffffff',
      secondary: '#7d878f'
    },
    sidebar: {
      background: '#111112',
      border: 'none'
    },
    button: {
      primaryFont: '#1e2327',
      primaryFontDisabled: '#828282',
      primaryBackgroundDisabled: '#666244',

      secondaryFont: '#ffffff',
      secondaryFontDisabled: '#6b6b6b',
      secondaryBackground: '#686868',
      secondaryBackgroundHover: '#8e8e8e',
      secondaryBackgroundActive: '#bebebe',
      secondaryBackgroundDisabled: '#848484'
    },
    input: {
      label: '#eaeaea',
      border: '#95959a',
      background: '#0a0c0d',
      placeholder: '',
      value: '#d8d8d8',
      borderFocus: '#ffffff',
      outlineFocus: '#8b8c91',
      arrowTrack: '#474952',
      arrows: '#252731',
      icon: '#eaeaea',
      dropdownItemBackground: '#32373B',
      dropdownItemFont: '#E3E6E9',
      dropdownItemBackgroundHover: '#3A4146',
      activeDropdownItemBackground: '#4E503F',
      activeDropdownItemFont: '#FFD800',
      activeDropdownItemBackgroundHover: '#565842'
    },
    profile: {
      avatarBackground: '#31373b',
      avatarIcon: '#ffd800',
      username: '#f7f7f7',
      occupation: '#919191'
    },
    sidemenu: {
      font: '#7d878f',
      fontActive: '#ffd800',
      backgroundActive: 'transparent',
      hightlightActive: 'solid 0.25rem #ffd800'
    },
    table: {
      header: '#7d878f',
      headerActive: '#eaeaea',
      border: 'solid 0.0625rem #4a5056'
    },
    orderStates: {
      waiting: '#eee129',
      preparing: '#9ae32b',
      delivering: '#3faee1',
      delivered: '#00ff00',
      canceled: '#dc2c2c'
    },
    range: {
      track: '#6F6F6F',
      thumb: '#FFD800',
      selected: '#debd03'
    },
    themeChanger: {
      track: '#1e2327',
      thumb: '#32373b',
      moon: '#dfedff',
      sun: '#685e19'
    },
    toolbarButton: {
      background: '#31373b',
      icon: '#c3c4c5'
    },
    progress: {
      font: '#ffffff',
      fontFinished: '#000000',
      background: '#677078',
      borderActive: 'solid 0.25rem #ffd800',
      backgroundActive: '#3d454d',
      backgroundFinished: '#ffd800',
      borderFinished: 'none'
    },
    logger: {
      border: '#DFBB05',
      username: '#FFED88',
      text: '#D8D8D8'
    },
    tooltip: {
      background: 'linear-gradient(to bottom, #1e2327, #111112)',
      label: '#ffffff',
      value: '#DFBB05'
    }
  }
}
