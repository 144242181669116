import { useEffect, useState } from 'react'
import { api } from '../api/api'
import { ProductItemInterface } from '../api/interfaces'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null
}
interface StateInterface {
  data: ProductItemInterface[] | null
  loading: boolean
  error: string | null
}

interface Hook {
  data: ProductItemInterface[] | null
  loading: boolean
  error: string | null
  refetch: () => Promise<void>
}

export const useGetProducts = (): Hook => {
  const [state, setState] = useState<StateInterface>(INITIAL_STATE)

  const query = async () => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const { data } = await api.get('/products')
      setState({ data: data.products, loading: false, error: null })
    } catch (err) {
      setState(current => ({ ...current, loading: false, error: err }))
    }
  }

  useEffect(() => {
    query()
  }, [])

  return { ...state, refetch: query }
}
