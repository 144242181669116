import styled from 'styled-components'

export const Grid = styled.div`
  width: 100%;
  height: calc(100vh - 9.375rem);

  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 17.735rem 1fr;
  grid-template-areas:
    'chart product'
    'logger logger';
`
