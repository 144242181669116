import React, { useState } from 'react'

import { Button, CloseButton } from '../../../../common/components'
import {
  Body,
  Column,
  Container,
  ExpandButton,
  Header,
  Label,
  OrderItemList,
  OrderListItem,
  Row,
  Status,
  Title,
  Value
} from './styles'
import { formatCurrency } from '../../../../utils/format'
import { OrderInterface } from '../../../../api/interfaces'
import { getWorkflowData } from '../../../../utils/workflow'
import { useGetOrders } from '../../../../hooks/useGetOrders'

interface OrderDetailInterface {
  onClose: () => void
  details: OrderInterface
}

export const OrderDetail: React.FC<OrderDetailInterface> = ({
  onClose,
  details
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const { changeStatus } = useGetOrders()

  const toggleItemsView = () => {
    setIsExpanded(current => !current)
  }

  const worflowData = getWorkflowData(
    details.status[details.status.length - 1].idStatus
  )

  return (
    <Container>
      <Header>
        <Title>Detalhes do pedido</Title>
        <CloseButton onClick={onClose} />
      </Header>
      <Body>
        <Row>
          <Column>
            <Label>Status</Label>
            <Status
              statusId={details.status[details.status.length - 1].idStatus}
            >
              {details.status[details.status.length - 1].status}
            </Status>
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>Nome do cliente</Label>
            <Value>{details.displayName}</Value>
          </Column>
        </Row>

        <Row>
          <Column>
            <Label>Itens</Label>
            <OrderItemList>
              {isExpanded ? (
                <>
                  {details.items.map(item => (
                    <OrderListItem key={item.details.idProduct}>
                      <span>{item.numberOfItems}</span>
                      {item.details.name}
                    </OrderListItem>
                  ))}
                  {details.items.length > 1 && (
                    <ExpandButton onClick={toggleItemsView}>
                      Ocultar
                    </ExpandButton>
                  )}
                </>
              ) : (
                <>
                  <OrderListItem>
                    <span>{details.items[0].numberOfItems}</span>
                    {details.items[0].details.name}
                  </OrderListItem>
                  {details.items.length > 1 && (
                    <ExpandButton onClick={toggleItemsView}>
                      E mais {details.items.length - 1}{' '}
                    </ExpandButton>
                  )}
                </>
              )}
            </OrderItemList>
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>Total</Label>
            <Value>{formatCurrency(details.totalOrder)}</Value>
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>Forma de pagamento</Label>
            <Value>{details.paymentMethod[0].payment}</Value>
          </Column>
          {!!details.paymentMethod[0].isChange && (
            <Column>
              <Label>Troco para</Label>
              <Value>R$ {details.orderChange}</Value>
            </Column>
          )}
        </Row>
        <Row>
          <Column>
            <Label>Endere&ccedil;o</Label>
            <Value>{details.address}</Value>
          </Column>
        </Row>
        <Row>
          {worflowData?.map(item => (
            <Button
              key={item.idStatus}
              primary
              onClick={() => {
                changeStatus(item.idStatus, details.idOrder)
                onClose()
              }}
            >
              {item.buttonText}
            </Button>
          ))}
        </Row>
      </Body>
    </Container>
  )
}
