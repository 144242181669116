import React, { ChangeEvent, useState } from 'react'
import { useTheme } from 'styled-components'

import {
  Container,
  ErrorMessage,
  FieldFocus,
  FieldWrapper,
  Label,
  ToggleButton
} from './styles'

import { ReactComponent as Warning } from './assets/warning.svg'
import { EyesOff, EyesOn } from './assets/Icons'

interface FieldProps {
  label: string
  name: string
  type?: 'text' | 'email' | 'tel' | 'password'
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  error?: string
  readOnly?: boolean
  disabled?: boolean
  disableAutoComplete?: boolean
}

export const TextField: React.FC<FieldProps> = ({
  label,
  name,
  type = 'text',
  value,
  onChange,
  error,
  readOnly,
  disabled,
  disableAutoComplete,
  ...rest
}) => {
  const [field, setField] = useState<'text' | 'password'>('password')
  const theme = useTheme()

  const handleToggleFieldVisibility = () => {
    setField(current => (current === 'password' ? 'text' : 'password'))
  }

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      {type === 'password' ? (
        <FieldWrapper hasError={!!error}>
          <input
            type={field}
            onChange={onChange}
            value={value}
            name={name}
            readOnly={readOnly}
            disabled={disabled}
            autoComplete={disableAutoComplete ? 'off' : 'on'}
            {...rest}
          />
          <ToggleButton type="button" onClick={handleToggleFieldVisibility}>
            {field === 'text' ? (
              <EyesOff fill={theme.colors.input.value} />
            ) : (
              <EyesOn fill={theme.colors.input.value} />
            )}
          </ToggleButton>
          <FieldFocus />
        </FieldWrapper>
      ) : (
        <FieldWrapper hasError={!!error}>
          <input
            type={type}
            onChange={onChange}
            value={value}
            name={name}
            readOnly={readOnly}
            disabled={disabled}
            autoComplete={disableAutoComplete ? 'off' : 'on'}
            {...rest}
          />
          <FieldFocus />
          {!!error && <Warning />}
        </FieldWrapper>
      )}
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
