import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useGetCategories } from '../../../../hooks/useGetCategories'
import { useGetProducts } from '../../../../hooks/useGetProducts'

import { EditProduct, ProductCard } from '../../components'

import {
  Button,
  SearchField,
  TagField,
  Toast
} from '../../../../common/components'

import { PageContainer } from '../../../../layout'

import {
  PageLayout,
  Content,
  Grid,
  NumberOfItems,
  Toolbar,
  ToolbarRight
} from './styles'

import { ReactComponent as Plus } from './assets/plus.svg'
import {
  CategoryInterface,
  ProductItemInterface
} from '../../../../api/interfaces'

const ProductList: React.FC = () => {
  const history = useHistory()
  const { data: categories, loading: loadingCategories } = useGetCategories()
  const {
    data: products,
    loading: loadingProducts,
    refetch: refetchProducts
  } = useGetProducts()

  const [searchString, setSearchString] = useState<string>('')
  const [tags, setTags] = useState<CategoryInterface[]>([])

  const [
    selectedProduct,
    setSelectedProduct
  ] = useState<ProductItemInterface | null>(null)

  function createNewProduct() {
    history.push('/product/new/step1')
  }

  function selectProduct(product: ProductItemInterface | null) {
    if (!selectedProduct) {
      setSelectedProduct(product)
    }
  }

  function containsAll(
    categoriesArray: CategoryInterface[],
    tagsArray: CategoryInterface[]
  ): boolean {
    let itIncludes = true
    for (let i = 0; i < tagsArray.length; i++) {
      if (
        !categoriesArray.find(
          category => category.idCategory === tagsArray[i].idCategory
        )
      ) {
        itIncludes = false
      }
    }

    return itIncludes
  }

  const taggedProducts: ProductItemInterface[] = useMemo(() => {
    if (tags.length) {
      return (
        products?.filter(product => containsAll(product.categories, tags)) || []
      )
    }

    return products || []
  }, [products, tags])

  const filteredProducts: ProductItemInterface[] = useMemo(() => {
    if (searchString) {
      return (
        taggedProducts?.filter(
          product =>
            product.name.toLowerCase().indexOf(searchString.toLowerCase()) !==
            -1
        ) || []
      )
    }

    return taggedProducts
  }, [products, searchString, tags])

  if (loadingCategories || loadingProducts) {
    return (
      <PageContainer title="Produtos" urlGroup="products">
        <Toast loading />
      </PageContainer>
    )
  }

  return (
    <PageContainer title="Produtos" urlGroup="products">
      <PageLayout>
        <Toolbar>
          <SearchField value={searchString} onChange={setSearchString} />
          <ToolbarRight>
            <TagField
              label="Categorias"
              value={tags}
              onChange={setTags}
              optionList={categories || []}
            />
            <Button primary onClick={createNewProduct}>
              <>
                <Plus />
                Adicionar produto
              </>
            </Button>
          </ToolbarRight>
        </Toolbar>
        <NumberOfItems>{`Exibindo ${filteredProducts.length} ${
          filteredProducts.length > 1 ? 'itens' : 'item'
        }`}</NumberOfItems>
        <Content isEditing={!!selectedProduct}>
          <Grid isEditing={!!selectedProduct}>
            {filteredProducts?.map(item => (
              <ProductCard
                key={item.idProduct}
                name={item.name}
                price={item.price}
                image={item.image}
                onClick={() => selectProduct(item)}
              />
            ))}
          </Grid>
          {!!selectedProduct && (
            <EditProduct
              product={selectedProduct}
              onClose={() => setSelectedProduct(null)}
              afterChange={refetchProducts}
            />
          )}
        </Content>
      </PageLayout>
    </PageContainer>
  )
}

export default ProductList
