import React, { createContext } from 'react'

import { Router } from 'react-router-dom'
import history from './history'
import { Routes } from './routes'

import { ThemeProvider } from 'styled-components'
import GlobalStyle from './styles/global'
import { light, dark } from './styles/themes'

import useTheme from './hooks/useTheme'

import { Theme } from './styles/styled'
import { AddProductProvider } from './context/AddProductContext'

interface ContextInterface {
  theme: Theme
  handleTheme: () => void
}

export const ThemeChanger = createContext({} as ContextInterface)

const App: React.FC = () => {
  const [theme, setTheme] = useTheme(light)

  function handleTheme() {
    setTheme(theme.title === 'light' ? dark : light)
  }

  return (
    <ThemeProvider theme={theme}>
      <ThemeChanger.Provider value={{ theme, handleTheme }}>
        <AddProductProvider>
          <Router history={history}>
            <Routes />
          </Router>
          <GlobalStyle />
        </AddProductProvider>
      </ThemeChanger.Provider>
    </ThemeProvider>
  )
}

export default App
