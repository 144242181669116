import React from 'react'
import { Toast } from '../../../../common/components'
import { useGetBestSeller } from '../../hooks/useGetBestSeller'
import {
  Container,
  Title,
  Content,
  ProductName,
  Label,
  Thumbnail
} from './styles'

export const BestSeller: React.FC = () => {
  const { data, loading } = useGetBestSeller()

  const product = data?.reduce(
    (acc, item) => {
      if (item.orders > acc.orders) {
        return item
      }
      return acc
    },
    { orders: 0, name: '', image: '' }
  )

  return (
    <Container isLoading={loading}>
      {loading && <Toast loading />}
      {!!data && !loading && (
        <>
          <Title>Produto mais vendido</Title>
          <Content>
            <div>
              <ProductName>{product?.name}</ProductName>
              <Label>Pedido {product?.orders} vezes essa semana</Label>
            </div>
            <Thumbnail src={product?.image} />
          </Content>
        </>
      )}
    </Container>
  )
}
