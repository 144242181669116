import React, { useState, useEffect } from 'react'
import {
  Container,
  Avatar,
  InfoContainer,
  Username,
  Occupation
} from './styles'

import { ReactComponent as ProfileIcon } from './assets/user.svg'

export const Profile: React.FC = () => {
  const [userName, setUsername] = useState<string>('')
  const [admin, setAdmin] = useState<number>(0)

  const getUserData = async () => {
    const user = await localStorage.getItem('@manie-delivery:user')

    if (user) {
      const { name, lastName, adm } = JSON.parse(user)
      setUsername(`${name} ${lastName}`)
      setAdmin(adm)
    }
  }

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <Container>
      <InfoContainer>
        <Username>{userName}</Username>
        <Occupation>{admin ? 'Administrador' : 'Operador'}</Occupation>
      </InfoContainer>
      <Avatar>
        <ProfileIcon />
      </Avatar>
    </Container>
  )
}
