import styled from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  align-items: center;
`

export const Container = styled.div`
  width: 87.5%;
  margin: 0 auto;

  @media (min-width: 85.375rem) {
    width: 100%;
    max-width: 69.375rem;
  }
`
export const HeaderBar = styled.header`
  width: 100%;
  height: 5rem;
  margin-bottom: 1rem;

  img {
    max-width: 100%;
    max-height: auto;
  }
`

export const LogoContainer = styled.div`
  width: 11rem;
  height: 5rem;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`

export const Form = styled.form`
  width: 100%;

  @media (min-width: 48rem) {
    max-width: 17.5rem;
  }
`

export const Button = styled.button`
  appearance: none;
  border: none;

  padding: 1rem 1.5rem;
  border-radius: 5px;
  background: linear-gradient(to top right, #000000, #2f4662);

  color: #ffffff;
  cursor: pointer;
`

export const ErrorMessage = styled.span`
  display: block;
  font-size: 0.875rem;
  color: #c71d1d;
  margin-bottom: 0.5rem;
`

export const SectionContainer = styled.div`
  flex: 1;
`
