export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export const formatCurrencyToText = (value: number): string => {
  const str = value.toFixed(2)
  const formattedString = str.replace(/,/g, '').replace('.', ',')

  return formattedString
}

export const currencyToNumber = (value: string): number => {
  const str = value.toString().replace(',', '.')

  return Number(str)
}
