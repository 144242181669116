import styled, { css } from 'styled-components'

interface UserProps {
  isActive?: boolean
}

export const Container = styled.div`
  width: 100%;
  height: 3.75rem;

  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  box-shadow: ${({ theme }) => theme.colors.shadow};

  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 3rem;

  cursor: pointer;

  /* overflow: hidden; */

  position: relative;

  margin-bottom: 0.625rem;
`

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.875rem;
`

export const UserFullName = styled.h4`
  margin: 0;
  padding: 0;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.primary};
`

export const UserName = styled.h3`
  margin: 0;
  padding: 0;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.secondary};
`

export const Profile = styled.h3`
  margin: 0;
  padding: 0;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.primary};
`

export const Status = styled.p`
  margin: 0;
  padding: 0;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 600;
  ${({ isActive }: UserProps) =>
    isActive
      ? css`
          color: ${({ theme }) => theme.colors.orderStates.delivering};
        `
      : css`
          color: ${({ theme }) => theme.colors.orderStates.canceled};
        `}
`

export const PopupButton = styled.button`
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;

  color: ${({ theme }) => theme.colors.font.primary};

  cursor: pointer;
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9990;

  background-color: ${({ theme }) => theme.colors.overlay};
`

export const OptionsPopup = styled.div`
  position: absolute;

  right: 0.625rem;
  top: 3rem;

  z-index: 10000;

  background-color: ${({ theme }) => theme.colors.blockBackground};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
`

export const Option = styled.div`
  width: 100%;
  padding: 0.5rem 1.5rem;

  cursor: pointer;

  font-size: 0.875rem;

  color: ${({ theme }) => theme.colors.font.primary};
`
