/* eslint-disable camelcase */
import { useEffect, useState } from 'react'

import { api } from '../api/api'
import { RequestErrorInterface } from '../api/interfaces'
import history from '../history'

export interface AuthProviderProps {
  authenticated: boolean
  loading: boolean
  error: RequestErrorInterface | string | null
  signInWithPassword: (user: string, password: string) => Promise<void>
  signOut: () => void
}

interface AuthState {
  authenticated: boolean
  loading: boolean
  error: RequestErrorInterface | string | null
}

const INITIAL_STATE = {
  authenticated: false,
  loading: true,
  error: null
}

export const useAuth = (): AuthProviderProps => {
  const [state, setState] = useState<AuthState>(INITIAL_STATE)

  const signOut = (): void => {
    localStorage.removeItem('@manie-delivery:token')
    localStorage.removeItem('@manie-delivery:user')
    localStorage.setItem('@manie-delivery:dark-theme', '0')
    // api.defaults.headers.Authorization = undefined
    delete api.defaults.headers.common.Authorization
    setState({ authenticated: false, loading: false, error: null })
    history.push('/login')
  }

  useEffect(() => {
    const token = localStorage.getItem('@manie-delivery:token')
    const user = localStorage.getItem('@manie-delivery:user')
    delete api.defaults.headers.common.Authorization
    if (token && user) {
      // api.defaults.headers.Authorization = `Bearer ${token}`
      setState({ authenticated: true, loading: false, error: null })
      return
    }
    signOut()
    setState({ authenticated: false, loading: false, error: null })
  }, [])

  const signInWithPassword = async (
    user: string,
    password: string
  ): Promise<void> => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const payload = JSON.stringify({ user, password })
      const { data } = await api.post('/cms/auth/login', payload)

      if (data.message) {
        setState(current => ({
          ...current,
          loading: false,
          error: data.message
        }))

        return
      }

      const { token } = data
      localStorage.setItem('@manie-delivery:token', token)
      localStorage.setItem('@manie-delivery:user', JSON.stringify(data.user[0]))
      localStorage.setItem(
        '@manie-delivery:dark-theme',
        JSON.stringify(data.user[0].darkMode)
      )
      // api.defaults.headers.Authorization = `Bearer ${token}`
      delete api.defaults.headers.common.Authorization
      history.push('/dashboard')
    } catch (err) {
      setState({ authenticated: false, loading: false, error: err.message })
    }
  }

  return { ...state, signInWithPassword, signOut }
}
