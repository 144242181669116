import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 19rem;
  display: flex;
  flex-direction: column;
  margin: 0;
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.inputs.labelFontSize};
  font-weight: ${({ theme }) => theme.inputs.labelFontWeight};
  color: ${({ theme }) => theme.colors.input.label};
  margin-bottom: 0.5rem;
`

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.inputs.height};

  input {
    appearance: none;
    border: none;

    position: relative;

    width: 100%;
    height: 100%;
    padding: 0 0.75rem 0 2.75rem;

    border: solid 1px ${({ theme }) => theme.colors.input.border};
    border-radius: ${({ theme }) => theme.buttons.radius};

    font-size: ${({ theme }) => theme.inputs.fontSize};
    font-weight: ${({ theme }) => theme.inputs.fontWeight};

    background: ${({ theme }) => theme.colors.input.background};
    color: ${({ theme }) => theme.colors.input.value};

    z-index: 1;

    transition: all 0.3s ease-in-out;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.75rem;
    z-index: 2;

    fill: ${({ theme }) => theme.colors.input.icon};
  }
`
