import React, { useState, useRef, useCallback, useEffect } from 'react'
import {
  Container,
  Label,
  FieldWrapper,
  ComboBox,
  TagList,
  ListItem,
  Tag,
  CaretButton,
  InputMessage,
  ErrorMessage,
  Overlay
} from './styles'

import { ReactComponent as CaretDown } from './assets/chevron-down.svg'
import { ReactComponent as Clear } from './assets/clear.svg'
import { CategoryInterface } from '../../../api/interfaces'

export interface TagProps {
  idCategory: string
  name: string
}

interface TagFieldProps {
  label: string
  value: CategoryInterface[]
  optionList: CategoryInterface[]
  onChange: (list: CategoryInterface[]) => void
  error?: string | string[]
}

export const TagField: React.FC<TagFieldProps> = ({
  label,
  value,
  onChange,
  optionList,
  error
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function toggleComboBox() {
    setIsOpen(current => !current)
  }

  const handleClickOutside = useCallback(event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false)
    }
  }, [])

  function handleRemoveTag(id: number) {
    const taglist = value.filter(
      (item: CategoryInterface) => item.idCategory !== id
    )
    onChange(taglist)
  }

  function handleAddTag(item: CategoryInterface) {
    const taglist = [...value, item]
    onChange(taglist)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [handleClickOutside])

  return (
    <Container>
      {isOpen && <Overlay />}
      <Label hasFocus={isOpen}>{label}</Label>
      <FieldWrapper ref={ref} hasFocus={isOpen}>
        {!!value.length && (
          <Tag onClick={() => handleRemoveTag(value[0].idCategory)}>
            {value[0].name}
            <Clear />
          </Tag>
        )}

        {value.length > 1 && (
          <InputMessage>E mais {value.length - 1}</InputMessage>
        )}
        <CaretButton open={isOpen} onClick={toggleComboBox} type="button">
          <CaretDown type="button" />
        </CaretButton>
        {isOpen && (
          <ComboBox>
            <TagList>
              {optionList.map(item => (
                <ListItem
                  key={item.idCategory}
                  selected={value.includes(item)}
                  onClick={
                    value.includes(item)
                      ? () => handleRemoveTag(item.idCategory)
                      : () => handleAddTag(item)
                  }
                >
                  {item.name}
                </ListItem>
              ))}
            </TagList>
          </ComboBox>
        )}
      </FieldWrapper>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
