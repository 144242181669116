import styled, { keyframes } from 'styled-components'

const explode = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
`

export const Container = styled.div`
  position: relative;
  width: ${({ theme }) => theme.inputs.height};
  height: ${({ theme }) => theme.inputs.height};
  border-radius: ${({ theme }) => theme.inputs.borderRadius};

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.toolbarButton.background};

  svg {
    fill: ${({ theme }) => theme.colors.toolbarButton.icon};
  }
`

export const AnimatedDot = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.625rem;

  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;

  background: #debd03;
  font-size: 0.5rem;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    display: block;
    position: absolute;

    width: 0.75rem;
    height: 0.75rem;
    opacity: 1;
    border-radius: 50%;

    animation: ${explode} 2s ease-in-out infinite normal;
    transform-origin: center;

    background: #ffe44e;
  }
`
