import { useEffect, useState } from 'react'
import { api } from '../api/api'
import { RequestErrorInterface, StatusInterface } from '../api/interfaces'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null
}

interface StateInterface {
  data: StatusInterface[] | null
  loading: boolean
  error: RequestErrorInterface | null
}

export const useGetStatuses = (): StateInterface => {
  const [state, setState] = useState<StateInterface>(INITIAL_STATE)

  const query = async () => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const { data } = await api.get('/status')
      setState({ data: data.Status, loading: false, error: null })
    } catch (err) {
      setState(current => ({ ...current, loading: false, error: err }))
    }
  }

  useEffect(() => {
    query()
  }, [])

  return state
}
