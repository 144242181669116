interface WorkflowInterface {
  idStatus: number
  buttonText: string
}

export const getWorkflowData = (
  currentStatus: number
): WorkflowInterface[] | null => {
  if (currentStatus === 1) {
    return [
      { idStatus: 2, buttonText: 'Aceitar' },
      { idStatus: 7, buttonText: 'Rejeitar' }
    ]
  }

  if (currentStatus === 2) {
    return [{ idStatus: 3, buttonText: 'Sair para entrega' }]
  }

  if (currentStatus === 3) {
    return [{ idStatus: 4, buttonText: 'Confirmar entrega' }]
  }

  return null
}
