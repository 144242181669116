import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.tooltip.background};
  padding: 0.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  text-align: left;

  h4 {
    margin: 0;
    color: ${({ theme }) => theme.colors.tooltip.label};
    font-weight: 400;
    font-size: 0.75rem;
  }
  h5 {
    margin: 0;
    color: ${({ theme }) => theme.colors.tooltip.value};
    font-weight: 600;
    font-size: 0.75rem;
  }
`
