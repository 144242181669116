import { useEffect, useState } from 'react'
import { api } from '../api/api'
import { CategoryInterface } from '../api/interfaces'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null
}
interface StateInterface {
  data: CategoryInterface[] | null
  loading: boolean
  error: string | null
}

export const useGetCategories = (): StateInterface => {
  const [state, setState] = useState<StateInterface>(INITIAL_STATE)

  const query = async () => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const { data } = await api.get('/categories')
      setState({ data: data.categories, loading: false, error: null })
    } catch (err) {
      setState(current => ({ ...current, loading: false, error: err }))
    }
  }

  useEffect(() => {
    query()
  }, [])

  return state
}
