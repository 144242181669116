import { useEffect, useState } from 'react'
import { api } from '../api/api'
import { OrderInterface, RequestErrorInterface } from '../api/interfaces'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null
}

interface StateInterface {
  data: OrderInterface[] | null
  loading: boolean
  error: RequestErrorInterface | null
}
interface Hook {
  data: OrderInterface[] | null
  loading: boolean
  error: RequestErrorInterface | null
  refetch: () => Promise<void>
  changeStatus: (idStatus: number, idOrder: number) => Promise<void>
}

export const useGetOrders = (): Hook => {
  const [state, setState] = useState<StateInterface>(INITIAL_STATE)

  const query = async () => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const { data } = await api.get('/order')
      setState({ data: data.order, loading: false, error: null })
    } catch (err) {
      setState(current => ({ ...current, loading: false, error: err }))
    }
  }

  const changeStatus = async (idStatus: number, idOrder: number) => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const user = localStorage.getItem('@manie-delivery:user')
      if (user) {
        const { idUser } = JSON.parse(user)
        const payload = JSON.stringify({ idUser, idOrder, idStatus })
        await api.post('/order/edit', payload)
        query()
        setState(current => ({ ...current, loading: false, error: null }))
      }
    } catch (e) {
      setState(current => ({ ...current, loading: false, error: e }))
    }
  }

  useEffect(() => {
    query()
  }, [])

  return { ...state, refetch: query, changeStatus }
}
