import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'

import { FiUserPlus } from 'react-icons/fi'
import { UserCMS } from '../../../../api/interfaces'

import { Button, Toast } from '../../../../common/components'
import { NewUserInterface, useGetUsers } from '../../../../hooks/useGetUsers'
import { PageContainer } from '../../../../layout'
import { NewUser, UserItem } from '../../components'
import {
  Box,
  Content,
  FloatingButtonContainer,
  Table,
  TableBody,
  TableHeader
} from './styles'

const Profile: React.FC = () => {
  const history = useHistory()
  const [newUserModalOpen, setNewUserModalOpen] = useState<boolean>(false)
  const {
    loading: gettingUsers,
    data: users,
    promote,
    activate,
    add
  } = useGetUsers()

  const handleAddUser = async (newUser: NewUserInterface) => {
    await add(newUser)
  }

  const handlePromoteUser = async (user: UserCMS, up: boolean) => {
    await promote(user, up)
  }

  const handleActiveUsers = async (user: UserCMS, active: number) => {
    await activate(user, active)
  }

  const usernameExists = (username: string): boolean => {
    const matchedUsers = users?.filter(item => item.user === username)
    return !!matchedUsers?.length
  }

  const isAdmin = () => {
    const usr = localStorage.getItem('@manie-delivery:user')
    if (usr) {
      const { adm } = JSON.parse(usr)
      if (adm) return
    }
    history.push('/')
  }

  useEffect(() => {
    isAdmin()
  }, [])

  return (
    <>
      <PageContainer title="Gerenciar perfis" urlGroup="profile">
        <Content>
          <Box>
            {gettingUsers ? (
              <Toast loading />
            ) : (
              <Table>
                <TableHeader>
                  <span>Nome / Sobrenome</span>
                  <span>Usuário</span>
                  <span>Perfil</span>
                  <span>Status</span>
                </TableHeader>
                <TableBody>
                  {users?.map(user => (
                    <UserItem
                      key={user.idUser}
                      id={user.idUser}
                      firstName={user.name}
                      lastName={user.lastName}
                      username={user.user}
                      active={!!user.active}
                      admin={!!user.adm}
                      onPromoteUser={() => handlePromoteUser(user, !user.adm)}
                      onChangeUserActivity={() =>
                        handleActiveUsers(user, user.active === 1 ? 0 : 1)
                      }
                    />
                  ))}
                </TableBody>
                <FloatingButtonContainer>
                  <Button primary onClick={() => setNewUserModalOpen(true)}>
                    <span>
                      <FiUserPlus />
                    </span>
                  </Button>
                </FloatingButtonContainer>
              </Table>
            )}
          </Box>
        </Content>
      </PageContainer>
      <NewUser
        open={newUserModalOpen}
        onCancel={() => setNewUserModalOpen(false)}
        onConfirm={handleAddUser}
        onUsernameCreate={usernameExists}
      />
    </>
  )
}

export default Profile
