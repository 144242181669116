import React from 'react'
import { Toast } from '../../../../common/components'
import { useGetConfig } from '../../../../hooks/useGetConfig'
import { PageContainer } from '../../../../layout'
import {
  DeliveryEstimateForm,
  DeliveryRadiusForm,
  LastModification,
  ToggleStoreForm,
  ToggleThemeForm
} from '../../components'
import { PlacesField } from '../../components/PlacesField'
import { Content, Box, BlockContainer } from './styles'

const Config: React.FC = () => {
  const {
    data,
    loading,
    handleChangeDeliveryTime,
    toggleStore,
    handleChangeRadius,
    handleChangeAddress
  } = useGetConfig()

  if (loading) {
    return (
      <PageContainer title="Configurações" urlGroup="config">
        <Toast loading />
      </PageContainer>
    )
  }

  return (
    <PageContainer title="Configurações" urlGroup="config">
      <Content>
        <Box>
          <BlockContainer>
            <ToggleStoreForm
              isOpen={!!data?.isOpen}
              toggleStore={toggleStore}
            />
            <ToggleThemeForm />
            <DeliveryRadiusForm
              initialRadius={data?.orderRange as number}
              onChange={handleChangeRadius}
            />
            <DeliveryEstimateForm
              timeMin={data?.timeOrderMin as number}
              timeMax={data?.timeOrderMax as number}
              onChange={handleChangeDeliveryTime}
            />
            <PlacesField
              address={data?.address as string}
              lat={Number(data?.lat)}
              lng={Number(data?.lng)}
              onChange={handleChangeAddress}
            />
            <LastModification
              name={data?.name as string}
              lastName={data?.lastName as string}
              date={data?.updatedAt as string}
            />
          </BlockContainer>
        </Box>
      </Content>
    </PageContainer>
  )
}

export default Config
