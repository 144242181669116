import React, { useState, useEffect } from 'react'

import { ErrorText, Row } from './styles'
import { Button, NumberField } from '../../../../common/components'
import { FormBlock, Title, Text } from '../../pages/Config/styles'

interface DeliveryEstimate {
  timeMin: number
  timeMax: number
  onChange: (timeMin: number, timeMax: number) => Promise<void>
}

interface DeliveryEstimateFormInterface {
  timeMin: number
  timeMax: number
}

export const DeliveryEstimateForm: React.FC<DeliveryEstimate> = ({
  timeMin,
  timeMax,
  onChange
}) => {
  const [formValues, setFormValues] = useState<DeliveryEstimateFormInterface>({
    timeMin,
    timeMax
  })

  const [error, setError] = useState<{ hasError: boolean; message: string }>({
    hasError: false,
    message: ''
  })

  const handleChangeMin = (value: number) => {
    setFormValues(current => ({
      ...current,
      timeMin: value
    }))
  }

  const handleChangeMax = (value: number) => {
    setFormValues(current => ({
      ...current,
      timeMax: value
    }))
  }

  const handleErrorCheck = () => {
    if (!formValues.timeMin || !formValues.timeMax) {
      setError({
        hasError: true,
        message: 'Os campos são obrigatórios'
      })
      return
    }

    if (formValues.timeMin >= formValues.timeMax) {
      setError({
        hasError: true,
        message: 'Tempo mínimo deve ser menor que tempo máximo'
      })
      return
    }
    setError({ hasError: false, message: '' })
  }

  const handleSubmitChange = () => {
    onChange(formValues.timeMin, formValues.timeMax)
  }

  useEffect(() => {
    setFormValues({
      timeMin,
      timeMax
    })
  }, [timeMin, timeMax])

  useEffect(() => {
    handleErrorCheck()
  }, [formValues])

  return (
    <FormBlock noValidate>
      <Title>Definir tempo médio de entrega</Title>
      <Text>Mínimo e máximo de tempo (em minutos) estimado para entrega</Text>
      <Row>
        <NumberField
          label="Mínimo"
          name="timeMin"
          value={formValues.timeMin}
          onChange={handleChangeMin}
        />
        <NumberField
          label="Máximo"
          name="timeMax"
          value={formValues.timeMax}
          onChange={handleChangeMax}
        />
      </Row>
      {error.hasError && (
        <Row>
          <ErrorText>{error.message}</ErrorText>
        </Row>
      )}
      <Button
        primary
        type="button"
        disabled={
          (timeMin === formValues.timeMin && timeMax === formValues.timeMax) ||
          error.hasError
        }
        onClick={handleSubmitChange}
      >
        Salvar
      </Button>
    </FormBlock>
  )
}
