import React from 'react'

import { Button } from '..'

import { DialogBox, DialogFooter, Overlay, Title, Text } from './styles'

interface ModalProps {
  title: string
  text?: string
  cancelLabel: string
  onCancel: () => void
  confirmLabel: string
  onConfirm: (value?: unknown) => void
}

export const Modal: React.FC<ModalProps> = ({
  title,
  text,
  cancelLabel,
  onCancel,
  confirmLabel,
  onConfirm
}) => {
  return (
    <>
      <Overlay />
      <DialogBox>
        <Title>{title}</Title>
        {!!text && <Text>{text}</Text>}
        <DialogFooter>
          <Button type="button" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="button" primary onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </DialogFooter>
      </DialogBox>
    </>
  )
}
