import React from 'react'
import { Button } from '..'
import { ReactComponent as CloseSign } from './clear.svg'

interface CloseButtonProps {
  onClick: () => void
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <Button isIconOnly onClick={onClick}>
      <CloseSign />
    </Button>
  )
}
