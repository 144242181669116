import styled from 'styled-components'

export const CardContainer = styled.div`
  width: 11.25rem;
  height: 17.1875rem;
  padding: 0.75rem;
  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  box-shadow: ${({ theme }) => theme.colors.shadow};
`

export const Picture = styled.img`
  width: 9.75rem;
  height: 9.75rem;
  margin-bottom: 1rem;
`

export const Name = styled.span`
  display: block;
  width: 100%;
  height: 2.25rem;
  line-height: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1rem;

  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.font.secondary};
`

export const Price = styled.span`
  display: block;
  width: 100%;
  line-height: 1.5rem;

  font-size: 1.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.font.primary};
`
