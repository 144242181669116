import styled from 'styled-components'

export const PageLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 5rem 1fr;

  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  box-shadow: ${({ theme }) => theme.colors.shadow};
`

export const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
`
export const Box = styled.div`
  display: grid;
  grid-template-rows: 1fr;
`

export const Table = styled.div`
  display: grid;
  grid-template-rows: 3.75rem 1fr;
  overflow: hidden;
  max-height: fit-content;
`

export const TableHeader = styled.header`
  width: 100%;
  height: 3.75rem;

  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 1fr;

  overflow: hidden;

  border-top: ${({ theme }) => theme.colors.table.border};

  span {
    padding-left: 0.875rem;

    font-family: ${({ theme }) => theme.font.body};
    font-weight: 600;
    font-size: 0.75rem;

    line-height: 3.75rem;

    color: ${({ theme }) => theme.colors.font.secondary};
  }
`

export const TableBody = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 18.125rem);
  overflow-y: auto;
`

export const FloatingButtonContainer = styled.div`
  position: fixed;
  bottom: 3rem;
  right: 3rem;
`
