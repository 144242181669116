import React, { ChangeEvent } from 'react'

import {
  Container,
  ErrorMessage,
  FieldFocus,
  FieldWrapper,
  Label
} from './styles'

import { ReactComponent as Warning } from './assets/warning.svg'

interface FieldProps {
  label: string
  name: string
  value: string
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  error?: string
}

export const TextArea: React.FC<FieldProps> = ({
  label,
  name,
  value,
  onChange,
  error,
  ...rest
}) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <FieldWrapper hasError={!!error}>
        <textarea onChange={onChange} value={value} name={name} {...rest} />
        <FieldFocus />
        {!!error && <Warning />}
      </FieldWrapper>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
