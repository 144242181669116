/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import moment from 'moment'

import { formatCurrency } from '../../../../utils/format'
import {
  Address,
  Cell,
  Container,
  CustomerName,
  OrderTime,
  Status,
  TotalPrice,
  VerticalCell
} from './styles'
import { OrderInterface } from '../../../../api/interfaces'

interface OrderItemProps {
  onClick?: () => void
  details: OrderInterface
}

export const OrderItem: React.FC<OrderItemProps> = ({ details, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Cell>
        <Status statusId={details.status[details.status.length - 1].idStatus}>
          {details.status[details.status.length - 1].status}
        </Status>
      </Cell>
      <Cell>
        <OrderTime>
          {!!details.orderTime && moment(details.orderTime).format('HH:mm')}
        </OrderTime>
      </Cell>
      <VerticalCell>
        <CustomerName>{details.displayName}</CustomerName>
        <Address>{details.address}</Address>
      </VerticalCell>
      <Cell>
        <TotalPrice>{formatCurrency(details.totalOrder)}</TotalPrice>
      </Cell>
    </Container>
  )
}
