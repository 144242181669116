import { useEffect, useState } from 'react'
import { api } from '../api/api'
import { UserCMS } from '../api/interfaces'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  success: null
}
interface StateInterface {
  data: UserCMS[] | null
  loading: boolean
  error: string | null
  success: boolean | null
}

interface Hook {
  data: UserCMS[] | null
  loading: boolean
  error: string | null
  refetch: () => Promise<void>
  promote: (user: UserCMS, up: boolean) => Promise<void>
  activate: (user: UserCMS, active: number) => Promise<void>
  add: (user: NewUserInterface) => Promise<void>
}

export interface NewUserInterface {
  name: string
  lastName: string
  user: string
  adm: number
  darkMode: number
  active: number
  password: string
}

export const useGetUsers = (): Hook => {
  const [state, setState] = useState<StateInterface>(INITIAL_STATE)

  const query = async () => {
    setState(current => ({
      ...current,
      loading: true,
      error: null,
      success: null
    }))
    try {
      const { data } = await api.get('/cms/users')
      setState({
        data: data.UsersCMS,
        loading: false,
        error: null,
        success: null
      })
    } catch (err) {
      setState(current => ({ ...current, loading: false, error: err }))
    }
  }

  const promote = async (user: UserCMS, up: boolean) => {
    setState(current => ({
      ...current,
      loading: true,
      error: null,
      success: null
    }))
    const payload = JSON.stringify({ idUser: user.idUser, adm: up ? 1 : 0 })
    try {
      await api.post('/cms/users/edit', payload)
      setState(current => ({
        ...current,
        loading: false,
        error: null,
        success: true
      }))
      await query()
    } catch (err) {
      setState(current => ({
        ...current,
        loading: false,
        error: err,
        success: false
      }))
    }
  }

  const activate = async (user: UserCMS, active: number) => {
    setState(current => ({
      ...current,
      loading: true,
      error: null,
      success: null
    }))
    const payload = JSON.stringify({ idUser: user.idUser, active: active })
    try {
      await api.post('/cms/users/edit', payload)
      setState(current => ({
        ...current,
        loading: false,
        error: null,
        success: true
      }))
      await query()
    } catch (err) {
      setState(current => ({
        ...current,
        loading: false,
        error: err,
        success: false
      }))
    }
  }

  const add = async (user: NewUserInterface) => {
    setState(current => ({
      ...current,
      loading: true,
      error: null,
      success: null
    }))
    const payload = JSON.stringify(user)
    try {
      await api.post('/cms/users/add', payload)
      setState(current => ({
        ...current,
        loading: false,
        error: null,
        success: true
      }))
      await query()
    } catch (err) {
      setState(current => ({
        ...current,
        loading: false,
        error: err,
        success: false
      }))
    }
  }

  useEffect(() => {
    query()
  }, [])

  return { ...state, refetch: query, promote, activate, add }
}
