import React from 'react'
import { Container, FieldWrapper, Label } from './styles'

import { ReactComponent as SearchIcon } from './assets/search.svg'

interface SearchProps {
  onChange: (searchString: string) => void
  value: string
}

export const SearchField: React.FC<SearchProps> = ({ onChange, value }) => {
  function onChangeText(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value)
  }

  return (
    <Container>
      <Label htmlFor="">Busca de produtos</Label>
      <FieldWrapper>
        <input type="text" value={value} onChange={onChangeText} />
        <SearchIcon />
      </FieldWrapper>
    </Container>
  )
}
