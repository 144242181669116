import styled from 'styled-components'

export const Container = styled.aside`
  width: 14.375rem;
  height: 100vh;
  display: grid;
  grid-template-rows: 8rem 1fr 3rem;

  background: ${({ theme }) => theme.colors.sidebar.background};
`

export const LogoContainer = styled.div`
  padding: 1.5rem 1.25rem;
`

export const LogoutButton = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  position: relative;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 3.75rem;
  cursor: pointer;

  span {
    font-family: ${({ theme }) => theme.font.headings};
    font-weight: 400;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.sidemenu.font};
  }

  svg {
    position: absolute;
    left: 2rem;
    fill: ${({ theme }) => theme.colors.sidemenu.font};
  }
`
