import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
  Button,
  CurrencyField,
  FormColumn,
  FormRow,
  FormWrapper,
  NumberField,
  TextArea,
  TextField
} from '../../../../common/components'
import { PageContainer } from '../../../../layout'
import { ProgressSteps } from '../../components'

import { NewProductContext } from '../../../../context/AddProductContext'

import { Content, Box, UpperContent, Footer, Form, Title } from './styles'

const AddProductFirstStep: React.FC = () => {
  const history = useHistory()
  const { value, setValue, resetProductCreation } = useContext(
    NewProductContext
  )

  const validationSchema = yup.object().shape({
    name: yup.string().required('Esse campo é obrigatório').trim(),
    desc: yup.string().required('Esse campo é obrigatório'),
    amount: yup.number(),
    price: yup.string().required('Esse campo é obrigatório')
  })

  const {
    isValid,
    values,
    touched,
    errors,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    validateForm,
    submitForm
  } = useFormik({
    initialValues: value.step1,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: productValues => {
      const step1 = {
        name: productValues.name,
        desc: productValues.desc,
        amount: productValues.amount,
        price: productValues.price
      }

      setValue(current => ({ ...current, step1: step1 }))
      history.push('/product/new/step2')
    }
  })

  const handleCancel = () => {
    resetProductCreation()
    history.push('/product/list')
  }

  const onChangePrice = (value: string) => {
    setFieldValue('price', value)
    setFieldTouched('price', true, false)
    validateForm({
      ...values,
      price: value
    })
  }

  return (
    <PageContainer title="Adicionar produto" urlGroup="products">
      <Content>
        <Box>
          <UpperContent>
            <ProgressSteps currentStep={1} numberOfSteps={4} />
            <Title>Adicione nome, descrição, preço e quantidade</Title>
            <Form onSubmit={handleSubmit}>
              <FormWrapper>
                <FormRow>
                  <FormColumn>
                    <TextField
                      label="Nome"
                      error={errors.name && touched.name ? errors.name : ''}
                      {...getFieldProps('name')}
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <TextArea
                      label="Descrição"
                      error={errors.desc && touched.desc ? errors.desc : ''}
                      {...getFieldProps('desc')}
                    />
                  </FormColumn>
                </FormRow>
                <FormRow columns="8.5rem 5rem">
                  <FormColumn>
                    <CurrencyField
                      label="Preço"
                      name="price"
                      error={errors.price && touched.price ? errors.price : ''}
                      onChange={onChangePrice}
                      value={values.price.toString()}
                    />
                  </FormColumn>
                  <FormColumn>
                    <NumberField
                      label="Estoque"
                      error={
                        errors.amount && touched.amount ? errors.amount : ''
                      }
                      name="amount"
                      value={values.amount}
                      onChange={value => setFieldValue('amount', value)}
                    />
                  </FormColumn>
                </FormRow>
              </FormWrapper>
            </Form>
          </UpperContent>
          <Footer>
            <Button type="button" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button
              type="button"
              primary
              disabled={!isValid}
              onClick={submitForm}
            >
              Próximo
            </Button>
          </Footer>
        </Box>
      </Content>
    </PageContainer>
  )
}

export default AddProductFirstStep
