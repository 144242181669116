import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
`

export const Avatar = styled.div`
  width: ${({ theme }) => theme.inputs.height};
  height: ${({ theme }) => theme.inputs.height};
  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.profile.avatarBackground};

  svg {
    fill: ${({ theme }) => theme.colors.profile.avatarIcon};
  }
`

export const InfoContainer = styled.div`
  text-align: right;
  padding-right: 0.875rem;
`

export const Username = styled.span`
  display: block;
  font-size: 1rem;
  font-weight: 700;
  height: 1.25rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.profile.username};
`

export const Occupation = styled.span`
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  height: 1.125rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.profile.occupation};
`
