import styled, { css } from 'styled-components'

interface IRow {
  columns?: string
  alignRight?: boolean
  alignCenter?: boolean
}

export const Container = styled.div`
  width: 100%;
  max-width: 21.25rem;
  margin: 0 auto;
`

export const Row = styled.div`
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  display: grid;
  grid-template-columns: ${({ columns }: IRow) => columns || '1fr'};
  column-gap: 1rem;
  margin-bottom: 1rem;
`

export const Column = styled.div`
  width: 100%;
  display: block;
`

export const Footer = styled.footer`
  width: 100%;
  padding-bottom: 0.5rem;

  display: flex;
  column-gap: 0.5rem;

  align-items: center;

  ${({ alignRight }: IRow) =>
    alignRight &&
    css`
      justify-content: flex-end;
    `}

  ${({ alignCenter }: IRow) =>
    alignCenter &&
    css`
      justify-content: center;
    `}
`
