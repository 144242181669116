import styled, { css } from 'styled-components'

interface Props {
  isLoading?: boolean
}

export const Container = styled.div`
  grid-area: chart;
  padding: 0.75rem;
  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  box-shadow: ${({ theme }) => theme.colors.shadow};

  ${({ isLoading }: Props) =>
    isLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`

export const Title = styled.h3`
  font-family: 'Encode Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.primary};
  margin: 0;
`

export const Label = styled.p`
  font-family: 'Encode Sans', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.secondary};
  margin: 0;
  margin-bottom: 1rem;
`
