import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  width: 100%;
  height: ${({ theme }) => theme.inputs.height};

  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: 1.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.primary};
  line-height: 2.5rem;
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 2rem;
`
