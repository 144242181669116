import React, { useMemo, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { OrderInterface } from '../../../../api/interfaces'
import { Toast } from '../../../../common/components'
import { useGetOrders } from '../../../../hooks/useGetOrders'
import { useGetStatuses } from '../../../../hooks/useGetStatuses'
import { PageContainer } from '../../../../layout'
import { OrderDetail, OrderItem } from '../../components'
import {
  Content,
  PageLayout,
  Table,
  TableBody,
  TableHeader,
  Toolbar,
  ToolbarItem
} from './styles'

interface LocationState {
  orderTab: string
}

const Orders: React.FC = () => {
  const location = useLocation<LocationState>()

  const { data: statuses, loading: loadingStatus } = useGetStatuses()
  const { data: orders, refetch } = useGetOrders()

  const [currentStatus, setCurrentStatus] = useState<number | null>(null)
  const [selectedOrder, setSelectedOrder] = useState<OrderInterface | null>(
    null
  )

  const handleFilterOrders = (statusId?: number): void => {
    if (!statusId) {
      setCurrentStatus(null)
    } else {
      setCurrentStatus(statusId)
    }
  }

  const handleSelectOrder = (item: OrderInterface) => {
    setSelectedOrder(item)
  }

  const handleClearSelectedOrder = (): void => {
    setSelectedOrder(null)
    refetch()
  }

  const filteredOrders = useMemo(() => {
    if (currentStatus) {
      return orders?.filter(
        order =>
          order.status[order.status.length - 1]?.idStatus === currentStatus
      )
    }
    return orders
  }, [currentStatus, orders])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 15000)

    if (location.state?.orderTab) {
      handleFilterOrders(1)
    }

    return () => {
      clearInterval(interval)
    }
  }, [])

  if (loadingStatus) {
    return (
      <PageContainer title="Pedidos" urlGroup="orders">
        <Toast loading />
      </PageContainer>
    )
  }

  return (
    <PageContainer title="Pedidos" urlGroup="orders">
      <PageLayout>
        <Toolbar>
          <ToolbarItem
            onClick={() => handleFilterOrders()}
            isSelected={!currentStatus}
          >
            Todos
          </ToolbarItem>
          {statuses?.map(item => (
            <ToolbarItem
              onClick={() => handleFilterOrders(item.idStatus)}
              isSelected={currentStatus === item.idStatus}
              key={item.idStatus}
            >
              {item.status}
            </ToolbarItem>
          ))}
        </Toolbar>
        <Content isEditing={!!selectedOrder}>
          <Table>
            <TableHeader>
              <span>Status</span>
              <span>Hora do pedido</span>
              <span>Nome do cliente/ Endereço</span>
              <span>Valor total</span>
            </TableHeader>
            {/* {loadingOrders && (
              <LoadingContainer>
                <Toast loading text="Atualizando pedidos" />
              </LoadingContainer>
            )} */}
            <TableBody>
              {filteredOrders?.map(order => (
                <OrderItem
                  key={order.idOrder}
                  details={order}
                  onClick={() => handleSelectOrder(order)}
                />
              ))}
            </TableBody>
          </Table>
          {!!selectedOrder && (
            <OrderDetail
              details={selectedOrder}
              onClose={handleClearSelectedOrder}
            />
          )}
        </Content>
      </PageLayout>
    </PageContainer>
  )
}

export default Orders
