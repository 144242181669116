import React, { useContext } from 'react'

import { ReactComponent as Sun } from './assets/sun.svg'
import { ReactComponent as Moon } from './assets/moon.svg'
import { Container, Thumb } from './styles'
import { ThemeChanger } from '../../../../App'

export const ToggleTheme: React.FC = () => {
  const { theme, handleTheme } = useContext(ThemeChanger)
  return (
    <Container type="button" onClick={handleTheme}>
      <Thumb isDark={theme.title === 'dark'} />
      <Moon />
      <Sun />
    </Container>
  )
}
