import React from 'react'
import { StyledButton, StyledLink } from './styles'

interface ButtonProps {
  children: string | JSX.Element
  primary?: boolean
  disabled?: boolean
  link?: boolean
  href?: string
  target?: string
  onClick?: () => void
  isIconOnly?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export const Button: React.FC<ButtonProps> = ({
  children,
  primary,
  disabled,
  link,
  href,
  target = '_blank',
  onClick,
  isIconOnly = false,
  type = 'submit'
}) => {
  if (link) {
    return (
      <StyledLink
        primary={primary}
        href={href}
        target={target}
        rel="noopener noreferrer"
      >
        {children}
      </StyledLink>
    )
  }
  return (
    <StyledButton
      primary={primary}
      onClick={onClick}
      disabled={disabled}
      iconOnly={isIconOnly}
      type={type}
    >
      {children}
    </StyledButton>
  )
}
