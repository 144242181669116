import styled, { css } from 'styled-components'

interface MenuProps {
  isActive?: boolean
}

export const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Item = styled.li`
  position: relative;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 3.75rem;
  cursor: pointer;

  span {
    font-family: ${({ theme }) => theme.font.headings};
    font-weight: 400;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.sidemenu.font};
  }

  svg {
    position: absolute;
    left: 2rem;
    fill: ${({ theme }) => theme.colors.sidemenu.font};
  }

  ${({ isActive }: MenuProps) =>
    isActive &&
    css`
      border-right: ${({ theme }) => theme.colors.sidemenu.hightlightActive};
      background: ${({ theme }) => theme.colors.sidemenu.backgroundActive};

      span {
        color: ${({ theme }) => theme.colors.sidemenu.fontActive};
      }

      svg {
        fill: ${({ theme }) => theme.colors.sidemenu.fontActive};
      }
    `}
`
