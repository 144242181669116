import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 17.375rem);
  overflow: hidden;

  display: grid;
  grid-template-rows: 3.75rem 1fr;

  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  box-shadow: ${({ theme }) => theme.colors.shadow};
`

export const Header = styled.header`
  width: 100%;
  height: 3.75rem;
  padding: 0 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h2`
  margin: 0;

  font-family: ${({ theme }) => theme.font.headings};
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.font.primary};
`

export const Body = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 1rem;
`
