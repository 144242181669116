import React from 'react'
import { Container, Title, Label } from './styles'
import { useGetWeeklyPurchases } from '../../hooks/useGetWeeklyPurchases'
import { Toast } from '../../../../common/components'

import {
  ResponsiveContainer,
  AreaChart,
  Area,
  Tooltip,
  CartesianGrid,
  XAxis
} from 'recharts'
import { CustomTooltip } from '../CustomTooltip'

export const PurchasesPerDay: React.FC = () => {
  const { data, loading } = useGetWeeklyPurchases()

  return (
    <Container isLoading={loading}>
      {loading && <Toast loading />}
      {!!data && !loading && (
        <>
          <Title>Pedidos por dia</Title>
          <Label>Últimos sete dias</Label>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart data={data}>
              <defs>
                <linearGradient id="background" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#ffd500" stopOpacity={0.9} />
                  <stop offset="100%" stopColor="#ffd500" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                dataKey="total"
                strokeWidth={7}
                stroke="#FFD800"
                fill="url(#background)"
              />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                opacity={0}
                height={0}
              />
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid opacity={0.1} vertical={false} />
            </AreaChart>
          </ResponsiveContainer>
        </>
      )}
    </Container>
  )
}
