import React, { ChangeEvent, useRef } from 'react'

import {
  Container,
  ErrorMessage,
  FieldFocus,
  FieldWrapper,
  Label,
  NumberContainer
} from './styles'

import { ReactComponent as ArrowUp } from './assets/arrow-up.svg'
import { ReactComponent as ArrowDown } from './assets/arrow-down.svg'

interface FieldProps {
  label: string
  name: string
  value: number
  onChange: (value: number) => void
  error?: string
}

export const NumberField: React.FC<FieldProps> = ({
  label,
  name,
  value = 0,
  onChange,
  error,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  function handleUpPress() {
    onChange(value + 1)
  }

  function handleDownPress() {
    if (value > 0) {
      onChange(value - 1)
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(Number(event?.target.value))
  }

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <FieldWrapper hasError={!!error}>
        <input
          type="number"
          onChange={handleChange}
          value={value}
          min={0}
          name={name}
          {...rest}
          ref={inputRef}
        />
        <FieldFocus />
        <NumberContainer>
          <button type="button" onClick={handleUpPress}>
            <ArrowUp />
          </button>
          <button type="button" onClick={handleDownPress}>
            <ArrowDown />
          </button>
        </NumberContainer>
      </FieldWrapper>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
