import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: ${({ theme }) => theme.colors.overlay};

  z-index: 9000;
`

export const CropperContainer = styled.div`
  position: fixed;
  width: 80vw;
  height: 90vh;

  padding: 1.25rem;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};

  display: grid;
  grid-template-rows: 1fr 10rem;

  > div {
    position: relative;
    width: 100%;
  }

  z-index: 10000;
`

export const RangeContainer = styled.div`
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    margin: 0;
    font-family: ${({ theme }) => theme.font.headings};
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.font.secondary};
  }
`
