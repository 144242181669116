import React, { ChangeEvent, useState } from 'react'
import { EyesOff, EyesOn } from '../../assets/Icons'

import {
  Container,
  ErrorMessage,
  FieldContainer,
  Field,
  Label,
  ToggleButton
} from './styles'

interface AuthField {
  name: string
  label: string
  type: 'text' | 'password'
  value: string
  error: string | null
  onChange: (fieldname: string, value: string) => void
}

export const TextField: React.FC<AuthField> = ({
  name,
  label,
  type,
  value,
  error,
  onChange
}) => {
  const [field, setField] = useState<'text' | 'password'>('password')

  const handleToggleFieldVisibility = () => {
    setField(current => (current === 'password' ? 'text' : 'password'))
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(name, event.target.value)
  }

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      {type === 'text' ? (
        <Field
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={handleChange}
        />
      ) : (
        <FieldContainer>
          <input
            type={field}
            name={name}
            id={name}
            value={value}
            onChange={handleChange}
          />
          <ToggleButton type="button" onClick={handleToggleFieldVisibility}>
            {field === 'text' ? <EyesOff /> : <EyesOn />}
          </ToggleButton>
        </FieldContainer>
      )}
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
