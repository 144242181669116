import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import {
  TextField,
  Button,
  Modal,
  TextArea,
  TagField,
  CurrencyField,
  NumberField,
  ImageUploader,
  CloseButton,
  FormFooter,
  FormRow,
  FormWrapper,
  Toast
} from '../../../../common/components'
import { ReactComponent as Trash } from './assets/trash.svg'

import { useGetCategories } from '../../../../hooks/useGetCategories'
import {
  useUpdateProduct,
  RequestPayload
} from '../../../../hooks/useUpdateProduct'

import {
  currencyToNumber,
  formatCurrencyToText
} from '../../../../utils/format'

import { Body, Container, Header, Title } from './styles'
import {
  CategoryInterface,
  ProductItemInterface
} from '../../../../api/interfaces'

interface EditProductProps {
  product: ProductItemInterface
  onClose: () => void
  afterChange: () => Promise<void>
}

export const EditProduct: React.FC<EditProductProps> = ({
  product,
  onClose,
  afterChange
}) => {
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const { data: categories, loading: loadingCategories } = useGetCategories()
  const {
    handleProductUpdate,
    handleDeleteProduct,
    loading: updatingProduct,
    success: updateSuccess
  } = useUpdateProduct()

  const initialValues = {
    name: product.name,
    desc: product.desc,
    categories: product.categories,
    price: formatCurrencyToText(product.price),
    amount: product.amount,
    image: product.image
  }
  const validationSchema = yup.object().shape({
    name: yup.string().required('Esse campo é obrigatório').trim(),
    desc: yup.string().required('Esse campo é obrigatório').trim(),
    categories: yup.array().min(1, 'É necessário ao menos uma categoria'),
    amount: yup.number().required(),
    price: yup.string().required()
  })

  const handleClose = (): void => {
    if (hasChanges) {
      setModalIsVisible(true)
      return
    }
    onClose()
  }
  const handleRemoveProduct = () => {
    handleDeleteProduct(product.idProduct, product.name)
    setShowDeleteModal(false)
  }

  const handleOpenDeletionModal = () => {
    setShowDeleteModal(true)
  }

  const {
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    validateForm,
    isValid,
    handleSubmit,
    getFieldProps
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: productValues => {
      const payload: RequestPayload = {
        idProduct: product.idProduct,
        name: productValues.name,
        desc: productValues.desc,
        amount: productValues.amount,
        ...(productValues.image !== initialValues.image && {
          image: productValues.image.split('base64,')[1]
        }),
        price: currencyToNumber(productValues.price),
        active: 1,
        categories: productValues.categories.map(item => ({
          idCategory: item.idCategory
        }))
      }

      handleProductUpdate(payload)
    }
  })

  const getChangeStatus = (): boolean => {
    if (initialValues.name !== values.name) {
      return true
    }

    if (initialValues.desc !== values.desc) {
      return true
    }

    if (initialValues.categories !== values.categories) {
      return true
    }

    if (initialValues.price !== values.price) {
      return true
    }

    if (initialValues.amount !== values.amount) {
      return true
    }

    if (initialValues.image !== values.image) {
      return true
    }

    return false
  }

  useEffect(() => {
    const isChanged = getChangeStatus()
    setHasChanges(isChanged && !updateSuccess)
  }, [values, updatingProduct])

  const onChangeCategories = (value: CategoryInterface[]) => {
    setFieldValue('categories', value)
    setFieldTouched('categories', true, false)
    validateForm({
      ...values,
      categories: value
    })
  }

  const onChangePrice = (value: string) => {
    setFieldValue('price', value)
    setFieldTouched('price', true, false)
    validateForm({
      ...values,
      price: value
    })
  }

  const onChangeImage = (value: string) => {
    setFieldValue('image', value)
    setFieldTouched('image', true, false)
    validateForm({
      ...values,
      image: value
    })
  }

  const onAfterChange = () => {
    afterChange()
    onClose()
  }

  if (loadingCategories) {
    return (
      <Container>
        <Header>
          <Title>Detalhes do produto</Title>
        </Header>
        <Body>
          <Toast loading />
        </Body>
      </Container>
    )
  }

  return (
    <>
      <Container>
        <Header>
          <Title>Detalhes do produto</Title>
          <CloseButton onClick={handleClose} />
        </Header>
        <Body>
          <form onSubmit={handleSubmit}>
            <FormWrapper>
              <FormRow>
                <TextField
                  label="Nome"
                  error={errors.name && touched.name ? errors.name : ''}
                  {...getFieldProps('name')}
                />
              </FormRow>
              <FormRow>
                <TextArea
                  label="Descrição"
                  error={errors.desc && touched.desc ? errors.desc : ''}
                  {...getFieldProps('desc')}
                />
              </FormRow>
              <FormRow>
                <TagField
                  label="Categorias"
                  error={
                    errors.categories && touched.categories
                      ? errors.categories.toString()
                      : ''
                  }
                  value={values.categories}
                  onChange={onChangeCategories}
                  optionList={categories || []}
                />
              </FormRow>
              <FormRow columns="8rem 1fr">
                <CurrencyField
                  label="Preço"
                  name="price"
                  error={errors.price && touched.price ? errors.price : ''}
                  onChange={onChangePrice}
                  value={values.price}
                />
                <NumberField
                  label="Estoque"
                  error={errors.amount && touched.amount ? errors.amount : ''}
                  name="amount"
                  value={values.amount}
                  onChange={value => setFieldValue('amount', value)}
                />
              </FormRow>
              <FormRow>
                <ImageUploader
                  label="Imagem"
                  value={values.image}
                  onChange={onChangeImage}
                />
              </FormRow>
              <FormFooter alignRight>
                <Button type="button" onClick={handleOpenDeletionModal}>
                  <>
                    <Trash />
                    Remover
                  </>
                </Button>
                <Button type="button" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  primary
                  disabled={!isValid || !hasChanges}
                >
                  Salvar
                </Button>
              </FormFooter>
            </FormWrapper>
          </form>
        </Body>
      </Container>
      {updatingProduct && <Toast loading lightbox text="Salvando alterações" />}
      {!!updateSuccess && (
        <Toast
          success
          lightbox
          text={updateSuccess}
          onConfirm={onAfterChange}
        />
      )}
      {modalIsVisible && (
        <Modal
          title="Deseja realmente sair?"
          text="É possível que as alterações feitas não sejam salvas."
          cancelLabel="Cancelar"
          onCancel={() => setModalIsVisible(false)}
          confirmLabel="Descartar"
          onConfirm={onClose}
        />
      )}
      {showDeleteModal && (
        <Modal
          title="Deseja realmente excluir o produto?"
          text="Essa ação não pode ser desfeita"
          cancelLabel="Cancelar"
          onCancel={() => setShowDeleteModal(false)}
          confirmLabel="Excluir"
          onConfirm={handleRemoveProduct}
        />
      )}
    </>
  )
}
