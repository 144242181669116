import React, { useRef, useState, useEffect, FormEvent } from 'react'
import { useHistory } from 'react-router-dom'

import {
  PageContainer,
  Container,
  Content,
  Form,
  HeaderBar,
  Button,
  ErrorMessage,
  LogoContainer,
  SectionContainer
} from './styles'
import { TextField } from '../../components'
import { useAuth } from '../../../../hooks/useAuth'
import { Toast } from '../../../../common/components'
import logo from '../../assets/logo--manie.png'

interface FormState {
  username: string
  password: string
}

export const SignIn: React.FC = () => {
  const firstRenderUser = useRef<boolean>(true)
  const firstRenderPwd = useRef<boolean>(true)
  const history = useHistory()
  const [formState, setFormState] = useState<FormState>({
    username: '',
    password: ''
  })
  const [errors, setErrors] = useState<FormState>({
    username: '',
    password: ''
  })

  const { authenticated, error, loading, signInWithPassword } = useAuth()

  const handleUpdateFormState = (fieldname: string, value: string) => {
    setFormState(current => ({ ...current, [fieldname]: value }))
  }

  const handleSubmitLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!errors.username && !errors.password) {
      signInWithPassword(formState.username, formState.password)
    }
  }

  useEffect(() => {
    if (authenticated && !loading) {
      history.push('/')
    }
  }, [loading])

  useEffect(() => {
    if (firstRenderUser.current) {
      firstRenderUser.current = false
      return
    }

    setErrors(current => ({
      ...current,
      username: !formState.username ? 'O campo usuário é obrigatório' : ''
    }))
  }, [formState.username])

  useEffect(() => {
    if (firstRenderPwd.current) {
      firstRenderPwd.current = false
      return
    }
    setErrors(current => ({
      ...current,
      password: !formState.password
        ? 'O campo senha é obrigatório'
        : formState.password.length < 8
        ? 'Campo senha deve ter no mínimo 8 dígitos'
        : ''
    }))
  }, [formState.password])

  return (
    <PageContainer>
      <Container>
        <HeaderBar>
          <LogoContainer>
            <img src={logo} alt="Manie Delivery" />
          </LogoContainer>
        </HeaderBar>
        <Content>
          <SectionContainer></SectionContainer>
          {loading ? (
            <Toast loading text="Verificando" />
          ) : (
            <Form noValidate onSubmit={handleSubmitLogin}>
              <TextField
                label="Usuário"
                type="text"
                name="username"
                value={formState.username}
                onChange={handleUpdateFormState}
                error={errors.username}
              />
              <TextField
                label="Senha"
                type="password"
                name="password"
                value={formState.password}
                onChange={handleUpdateFormState}
                error={errors.password}
              />
              {!!error && <ErrorMessage>{error}</ErrorMessage>}
              <Button type="submit">Entrar</Button>
            </Form>
          )}
        </Content>
      </Container>
    </PageContainer>
  )
}
