import React from 'react'
import { PageContainer } from '../../../../layout'
import { BestSeller, LogTable, PurchasesPerDay } from '../../components'
import { Grid } from './styles'

const Dashboard: React.FC = () => {
  return (
    <PageContainer title="Dashboard" urlGroup="dashboard">
      <Grid>
        <PurchasesPerDay />
        <BestSeller />
        <LogTable />
      </Grid>
    </PageContainer>
  )
}

export default Dashboard
