import styled, { css } from 'styled-components'

interface Props {
  hasError?: boolean
  selected?: boolean
  open?: boolean
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.inputs.labelFontSize};
  font-weight: ${({ theme }) => theme.inputs.labelFontWeight};
  color: ${({ theme }) => theme.colors.input.label};
  margin-bottom: 0.5rem;
`

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.inputs.height};

  display: flex;
  justify-content: center;
  align-items: center;

  input {
    appearance: none;
    border: none;

    position: relative;

    width: 100%;
    height: 100%;
    padding: ${({ theme }) => theme.inputs.padding};

    border: ${({ theme }) => theme.inputs.border};
    border-radius: ${({ theme }) => theme.inputs.borderRadius};

    background: ${({ theme }) => theme.colors.input.background};
    border-color: ${({ theme }) => theme.colors.input.border};
    color: ${({ theme }) => theme.colors.input.value};

    font-size: 0.875rem;
    font-weight: 400;

    z-index: 1;

    transition: all 0.3s ease-in-out;

    &:focus {
      border-color: ${({ theme }) => theme.colors.input.borderFocus};
    }

    &:focus + div {
      opacity: 1;
      transform: scale(1.01, 1.1);
    }
  }

  > svg {
    position: absolute;
    top: 0.875rem;
    right: 0.875rem;
    z-index: 1;

    fill: ${({ theme }) => theme.colors.error};
  }

  ${({ hasError }: Props) =>
    hasError &&
    css`
      input {
        border-color: ${({ theme }) => theme.colors.error};

        &:focus {
          border-color: ${({ theme }) => theme.colors.error};
        }

        &:focus + div {
          opacity: 0;
          transform: scale(1, 1);
        }
      }
    `}
`

export const ToggleButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.0625rem;
  z-index: 1;

  > svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`

export const FieldFocus = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;

  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  background: ${({ theme }) => theme.colors.input.outlineFocus};

  transition: all 0.3s ease-in-out;
  z-index: 0;
`

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.error};
`
