/* eslint-disable @typescript-eslint/no-explicit-any */
import { Area } from 'react-easy-crop/types'

function createImage(url: string | ArrayBuffer): Promise<any> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = typeof url === 'string' ? url : url.toString()
  })
}

function getRadianAngle(degree: number): number {
  return (degree * Math.PI) / 180
}

export default async function getCroppedImage(
  imageSource: string | ArrayBuffer,
  pixelCrop: Area,
  rotation = 0
): Promise<string> {
  const image = await createImage(imageSource)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  canvas.width = safeArea
  canvas.height = safeArea

  ctx?.translate(safeArea / 2, safeArea / 2)
  ctx?.rotate(getRadianAngle(rotation))
  ctx?.translate(-safeArea / 2, -safeArea / 2)

  ctx?.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.width * 0.5
  )

  const data = ctx?.getImageData(0, 0, safeArea, safeArea)

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  if (data) {
    ctx?.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.y
    )
  }

  return canvas.toDataURL('image/jpeg')
}

// export async function generateBase64(
//   imageSource: string,
//   crop: Area
// ): Promise<string> {
//   if (!crop || !imageSource) {
//     return
//   }

//   const canvas = await getCroppedImage(imageSource, crop)

//   canvas.toBlob(blob => )
// }
