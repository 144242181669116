import React, { useState } from 'react'
import {
  Container,
  Input,
  InputContainer,
  LeftSpan,
  RightSpan,
  ThumbLabel
} from './styles'

interface RangeInputProps {
  min?: number
  max: number
  value: number
  step: number
  onChange: (value: number) => void
  hideToolTip?: boolean
}

export const RangeInput: React.FC<RangeInputProps> = ({
  min = 0,
  max,
  value,
  step,
  onChange,
  hideToolTip = false
}) => {
  const [visibleThumb, setVisibleThumb] = useState<boolean>(false)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newvalue = Number(event.target.value)
    onChange(newvalue)
  }

  function handlePositionCalc(): number {
    if (min > 0) {
      const total = max - min
      const current = value - min

      return (current / total) * 100
    }

    return (value / max) * 100
  }

  function handleFocus() {
    setVisibleThumb(true)
  }

  function handleBlur() {
    setVisibleThumb(false)
  }

  return (
    <Container>
      <LeftSpan reachedValue={value === min}>{min}</LeftSpan>
      <InputContainer>
        {!hideToolTip && (
          <ThumbLabel visible={visibleThumb} left={handlePositionCalc()}>
            <span>{value}</span>
          </ThumbLabel>
        )}
        <Input
          type="range"
          onChange={handleChange}
          value={value}
          min={min}
          max={max}
          step={step}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </InputContainer>
      <RightSpan reachedValue={value === max}>{max}</RightSpan>
    </Container>
  )
}
