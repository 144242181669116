/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { api } from '../api/api'
import { RequestErrorInterface } from '../api/interfaces'

interface ReturnType {
  handleProductUpdate: (payload: RequestPayload) => void
  handleDeleteProduct: (idProduct: number, name: string) => Promise<void>
  loading: boolean
  error: RequestErrorInterface | null
  success: string | null
}

interface StateInterface {
  loading: boolean
  error: RequestErrorInterface | null
  success: string | null
}

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null
}

export interface RequestPayload {
  idProduct: number
  name: string
  desc: string
  price: number
  amount: number
  image?: string
  categories: Array<{
    idCategory: number
  }>
  active: 1
}

export const useUpdateProduct = (): ReturnType => {
  const [state, setState] = useState<StateInterface>(INITIAL_STATE)

  const handleProductUpdate = async (payload: RequestPayload) => {
    setState({ success: null, loading: true, error: null })
    try {
      const hasUser = localStorage.getItem('@manie-delivery:user')
      if (hasUser) {
        const { idUser } = JSON.parse(hasUser)
        const content = JSON.stringify({ ...payload, idUser: idUser })
        const { data } = await api.post('/products/edit', content)
        setState({ loading: false, error: null, success: data.editProduct })
      }
    } catch (e) {
      setState({ loading: false, error: e, success: null })
    }
  }

  const handleDeleteProduct = async (idProduct: number, name: string) => {
    setState({ success: null, loading: true, error: null })
    try {
      const hasUser = localStorage.getItem('@manie-delivery:user')
      if (hasUser) {
        const { idUser } = JSON.parse(hasUser)
        const payload = JSON.stringify({ idUser, idProduct, name })
        const { data } = await api.post('/products/remove', payload)
        setState({ loading: false, error: null, success: data.removeProduct })
      }
    } catch (err) {
      setState({ loading: false, error: err, success: null })
    }
  }

  return { ...state, handleProductUpdate, handleDeleteProduct }
}
