import styled, { css } from 'styled-components'

interface Props {
  isLoading?: boolean
}

export const Container = styled.div`
  grid-area: product;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  box-shadow: ${({ theme }) => theme.colors.shadow};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ isLoading }: Props) =>
    isLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`

export const Title = styled.h3`
  font-family: 'Encode Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.primary};
  margin: 0;
  margin-bottom: 1rem;
`

export const ProductName = styled.h4`
  font-family: 'Encode Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.font.primary};
  margin: 0;
  margin-bottom: 1rem;
`

export const Label = styled.p`
  font-family: 'Encode Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.secondary};
  margin: 0;
  margin-bottom: 1rem;
`

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 4rem);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 3rem;
`

export const Thumbnail = styled.img`
  border-radius: 6px;
  max-width: 100%;
  max-height: 100%;
`
