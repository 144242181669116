/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import moment from 'moment'

import { Container } from './styles'

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active) {
    return (
      <Container>
        <h4>{moment(label).format('DD [de] MMMM [de] YYYY')}</h4>
        <h5>{payload[0].value} pedidos</h5>
      </Container>
    )
  }
  return null
}
