/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { api } from '../api/api'
import { CategoryInterface, RequestErrorInterface } from '../api/interfaces'

interface RequestBody {
  name: string
  desc: string
  price: number
  amount: number
  categories: CategoryInterface[]
  image: string
}
interface StateInterface {
  success: string | null
  loading: boolean
  error: RequestErrorInterface | null
}

const INITIAL_STATE = {
  success: null,
  loading: false,
  error: null
}

interface RequestInterface {
  success: string | null
  loading: boolean
  error: RequestErrorInterface | null
  mutation: (data: RequestBody) => Promise<void>
}

interface ProductUploadData {
  name: string
  desc: string
  price: number
  image: string
  amount: number
  categories: Array<{
    idCategory: number
  }>
  active: number
  idUser: string
}

export const useCreateProduct = (): RequestInterface => {
  const [state, setState] = useState<StateInterface>(INITIAL_STATE)

  const handleMutation = async (data: RequestBody) => {
    const userData = localStorage.getItem('@manie-delivery:user')
    if (userData) {
      const { idUser } = JSON.parse(userData)
      setState({ loading: true, error: null, success: null })

      const payload: ProductUploadData = {
        name: data.name,
        desc: data.desc,
        price: data.price,
        image: data.image,
        amount: data.amount,
        categories: data.categories.map(item => ({
          idCategory: item.idCategory
        })),
        active: 1,
        idUser: idUser
      }

      try {
        const { data } = await api.post(
          '/products/add',
          JSON.stringify(payload)
        )

        setState({ loading: false, error: null, success: data.addProduct })
      } catch (e) {
        setState({ loading: false, error: e, success: null })
      }
    }
  }

  return { ...state, mutation: handleMutation }
}
