import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Dashboard from './modules/dashboard/pages/Dashboard/Dashboard'

import ProductList from './modules/product/pages/ProductList/ProductList'
import AddProductFirstStep from './modules/product/pages/AddProduct/Step1'
import AddProductSecondStep from './modules/product/pages/AddProduct/Step2'
import AddProductThirdStep from './modules/product/pages/AddProduct/Step3'
import AddProductFourthStep from './modules/product/pages/AddProduct/Step4'

import Orders from './modules/order/pages/Orders/Orders'

import Profile from './modules/profile/pages/Profile/Profile'

import Config from './modules/config/pages/Config/Config'

import { SignIn } from './modules/authentication/pages/SignIn'

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/login" component={SignIn} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/product/list" component={ProductList} />
      <Route exact path="/product/new/step1" component={AddProductFirstStep} />
      <Route exact path="/product/new/step2" component={AddProductSecondStep} />
      <Route exact path="/product/new/step3" component={AddProductThirdStep} />
      <Route exact path="/product/new/step4" component={AddProductFourthStep} />
      <Route exact path="/orders" component={Orders} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/config" component={Config} />
      <Route path="/*" render={() => <Redirect to="/dashboard" />} />
    </Switch>
  )
}
