import React, { useState } from 'react'

import { FiMoreVertical } from 'react-icons/fi'

import {
  Container,
  Cell,
  UserFullName,
  UserName,
  Profile,
  Status,
  OptionsPopup,
  PopupButton,
  Overlay,
  Option
} from './styles'

interface UserProps {
  id: number
  firstName: string
  lastName: string
  username: string
  active: boolean
  admin: boolean
  onChangeUserActivity: () => void
  onPromoteUser: () => void
}

export const UserItem: React.FC<UserProps> = ({
  firstName,
  lastName,
  username,
  active,
  admin,
  onChangeUserActivity,
  onPromoteUser
}) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleOpenPopup = () => {
    setOpen(true)
  }

  const handleChangeUserActivity = () => {
    onChangeUserActivity()
    setOpen(false)
  }

  const handlePromoteUser = () => {
    onPromoteUser()
    setOpen(false)
  }

  return (
    <>
      <Container>
        <Cell>
          <UserFullName>{`${firstName} ${lastName}`}</UserFullName>
        </Cell>
        <Cell>
          <UserName>{username}</UserName>
        </Cell>
        <Cell>
          <Profile>{admin ? 'Administrador' : 'Operador'}</Profile>
        </Cell>
        <Cell>
          <Status isActive={active}>{active ? 'Ativo' : 'Desativado'}</Status>
        </Cell>
        <Cell>
          <PopupButton type="button" onClick={handleOpenPopup}>
            <FiMoreVertical />
          </PopupButton>
        </Cell>
        {open && (
          <>
            <Overlay onClick={() => setOpen(false)} />
            <OptionsPopup>
              <Option onClick={handleChangeUserActivity}>
                {active ? 'Desativar' : 'Ativar'}
              </Option>
              <Option onClick={handlePromoteUser}>
                {!admin ? 'Promover a administrador' : 'Tornar operador'}
              </Option>
            </OptionsPopup>
          </>
        )}
      </Container>
    </>
  )
}
