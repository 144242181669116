import styled, { css } from 'styled-components'

interface RangeProps {
  visible?: boolean
  reachedValue?: boolean
  left?: number
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  padding: 0.875rem;

  position: relative;
`

export const LeftSpan = styled.span`
  display: block;
  width: 2.5rem;

  font-family: ${({ theme }) => theme.font.headings};
  font-size: 0.875rem;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.font.secondary};

  ${({ reachedValue }: RangeProps) =>
    reachedValue &&
    css`
      color: ${({ theme }) => theme.colors.range.selected};
      font-weight: 600;
    `}
`

export const RightSpan = styled.span`
  display: block;
  width: 2.5rem;

  text-align: right;

  font-family: ${({ theme }) => theme.font.headings};
  font-size: 0.875rem;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.font.secondary};

  ${({ reachedValue }: RangeProps) =>
    reachedValue &&
    css`
      color: ${({ theme }) => theme.colors.range.selected};
      font-weight: 600;
    `}
`

export const InputContainer = styled.div`
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
`

export const ThumbLabel = styled.div`
  position: relative;
  width: 100%;

  margin-bottom: 0.625rem;

  span {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    left: ${({ left }: RangeProps) => `${left}%`};
    top: -3.25rem;

    transform: ${({ visible }: RangeProps) =>
      visible ? 'translateX(-50%) scale(1)' : 'translateX(-50%) scale(0)'};

    width: 2.75rem;
    height: 2.75rem;

    border-radius: 50%;

    background: ${({ theme }) => theme.colors.primary.base};

    font-family: ${({ theme }) => theme.font.headings};
    font-size: 0.875remrem;
    font-weight: 500;
    text-align: center;

    line-height: 3.5rem;

    color: ${({ theme }) => theme.colors.button.primaryFont};

    transition: transform 0.3s ease-in-out;

    z-index: 2;
  }
`

export const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;

  border: none;
  outline: none;

  margin: 0.5rem 1.25rem;

  width: 100%;
  height: 0.25rem;

  border-radius: 1px;
  background: ${({ theme }) => theme.colors.range.track};

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;

    width: 1.25rem;
    height: 1.25rem;

    border-radius: 50%;

    background: ${({ theme }) => theme.colors.range.thumb};

    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.125rem;
    background: ${({ theme }) => theme.colors.range.thumb};
    border: solid 1px ${({ theme }) => theme.colors.range.track};
    cursor: pointer;
  }
`
