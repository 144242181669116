import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Aside, Header } from '..'
import { Toast } from '../../common/components'
import { useAuth } from '../../hooks/useAuth'

import {
  ContentPage,
  ContentSection,
  ContentWrapper,
  PrivatePage
} from './styles'

interface PageProps {
  title: string
  urlGroup: string
  children: JSX.Element
}

export const PageContainer: React.FC<PageProps> = ({
  title,
  urlGroup,
  children
}) => {
  const history = useHistory()
  const { authenticated, loading } = useAuth()

  useEffect(() => {
    if (!authenticated && !loading) {
      history.push('/login')
    }
  }, [])

  return (
    <PrivatePage>
      <Aside currentPage={urlGroup} />
      <ContentPage>
        <ContentSection>
          <Header title={title} />
          <ContentWrapper>
            {loading ? <Toast loading /> : children}
          </ContentWrapper>
        </ContentSection>
      </ContentPage>
    </PrivatePage>
  )
}
