import styled, { css } from 'styled-components'

interface Props {
  selected?: boolean
  open?: boolean
  hasFocus?: boolean
}

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: ${({ theme }) => theme.colors.overlay};

  z-index: 9000;
`

export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0;
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.inputs.labelFontSize};
  font-weight: ${({ theme }) => theme.inputs.labelFontWeight};
  color: ${({ theme }) => theme.colors.input.label};
  margin-bottom: 0.5rem;

  ${({ hasFocus }: Props) =>
    hasFocus &&
    css`
      position: relative;

      z-index: 9010;
    `}
`

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 18rem;
  height: ${({ theme }) => theme.inputs.height};

  border: solid 1px ${({ theme }) => theme.colors.input.border};
  border-radius: ${({ theme }) => theme.buttons.radius};
  background: ${({ theme }) => theme.colors.input.background};

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0 2rem 0 0.75rem;

  ${({ hasFocus }: Props) =>
    hasFocus &&
    css`
      position: relative;

      z-index: 9010;
    `}
`

export const CaretButton = styled.button`
  appearance: none;
  border: none;

  background: transparent;
  cursor: pointer;

  position: absolute;
  right: 0;

  width: 2rem;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;

  ${({ open }: Props) =>
    open &&
    css`
      transform: rotate(180deg);
    `}

  svg {
    fill: ${({ theme }) => theme.colors.input.icon};
  }
`

export const InputMessage = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.input.value};
`

export const ComboBox = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  margin-top: 0.25rem;
  background: ${({ theme }) => theme.colors.blockBackground};
  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  border: ${({ theme }) => theme.colors.blockBorder};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  z-index: 5000;
`

export const TagList = styled.ul`
  width: 100%;
  max-height: 8.75rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const ListItem = styled.li`
  flex-shrink: 0;
  flex-grow: 0;

  display: block;

  width: 100%;
  height: 2.5rem;
  padding: 0 0.75rem;

  font-size: 0.875rem;
  line-height: 2.5rem;
  text-transform: capitalize;

  background: ${({ theme }) => theme.colors.input.dropdownItemBackground};
  color: ${({ theme }) => theme.colors.input.dropdownItemFont};

  &:hover {
    background: ${({ theme }) =>
      theme.colors.input.dropdownItemBackgroundHover};
  }

  ${({ selected }: Props) =>
    selected &&
    css`
      background: ${({ theme }) =>
        theme.colors.input.activeDropdownItemBackground};
      color: ${({ theme }) => theme.colors.input.activeDropdownItemFont};

      &:hover {
        background: ${({ theme }) =>
          theme.colors.input.activeDropdownItemBackgroundHover};
      }
    `}
`

export const Tag = styled.span`
  position: relative;
  padding: 0.25rem 2.125rem 0.25rem 0.625rem;
  margin-right: 0.625rem;

  font-size: 0.875rem;
  font-weight: 400;
  text-transform: capitalize;

  border-radius: 0.25rem;

  background: #ffd800;

  svg {
    position: absolute;
    right: 0.625rem;
    top: 50%;

    margin-top: -7px;
  }
`

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.error};
`
