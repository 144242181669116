import { useEffect, useState } from 'react'
import { api } from '../../../api/api'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null
}

interface LogProps {
  idLogger: number
  name: string
  lastName: string
  action: string
  function: string
  updatedAt: string
}

interface StateInterface {
  data: LogProps[] | null
  loading: boolean
  error: string | null
}

interface Hook {
  data: LogProps[] | null
  loading: boolean
  error: string | null
}

export const useGetLogger = (): Hook => {
  const [state, setState] = useState<StateInterface>(INITIAL_STATE)

  const query = async () => {
    setState(current => ({
      ...current,
      loading: true,
      error: null,
      success: null
    }))
    try {
      const { data } = await api.get('/dash/logger', {
        headers: { 'content-type': 'application/json' }
      })
      setState({
        data: data.Logger,
        loading: false,
        error: null
      })
    } catch (err) {
      setState(current => ({ ...current, loading: false, error: err }))
    }
  }

  useEffect(() => {
    query()
  }, [])

  return state
}
