import styled, { css } from 'styled-components'

interface OrderItemProps {
  statusId?: number
}

export const Container = styled.div`
  width: 100%;
  height: 3.75rem;

  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  box-shadow: ${({ theme }) => theme.colors.shadow};

  display: grid;
  grid-template-columns: 1fr 1fr 5fr 1fr;

  cursor: pointer;

  overflow: hidden;

  margin-bottom: 0.625rem;
`

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.875rem;
`

export const VerticalCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0.875rem;
`

export const OrderTime = styled.p`
  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.primary};
`

export const Status = styled.h4`
  margin: 0;
  padding: 0;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 600;

  text-transform: capitalize;

  ${({ statusId }: OrderItemProps) => {
    switch (statusId) {
      case 1:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.waiting};
        `
      case 2:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.preparing};
        `
      case 3:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.delivering};
        `
      case 4:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.delivered};
        `
      case 5:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.canceled};
        `
      case 7:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.canceled};
        `
      default:
        return css`
          color: ${({ theme }) => theme.colors.font.primary};
        `
    }
  }}
`

export const CustomerName = styled.h4`
  margin: 0;
  padding: 0;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.primary};
`

export const Address = styled.p`
  margin: 0;
  padding: 0;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.secondary};
`

export const TotalPrice = styled.h4`
  margin: 0;
  padding: 0;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.primary};
`
