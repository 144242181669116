import styled, { css } from 'styled-components'

interface OrderListProps {
  isEditing?: boolean
  isSelected?: boolean
}

export const PageLayout = styled.div`
  display: grid;
  grid-template-rows: 5rem 1fr;
`
export const Toolbar = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ToolbarItem = styled.span`
  position: relative;

  display: block;
  width: fit-content;
  height: 1.75rem;
  padding: 0 1rem;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-transform: capitalize;

  cursor: pointer;

  ${({ isSelected }: OrderListProps) =>
    isSelected
      ? css`
          color: ${({ theme }) => theme.colors.table.headerActive};

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0.125rem;

            border-radius: 0.0625rem;

            background-color: ${({ theme }) => theme.colors.range.selected};

            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.table.header};
        `}
`
export const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  overflow: hidden;

  ${({ isEditing }: OrderListProps) =>
    isEditing &&
    css`
      grid-template-columns: 1fr 23.25rem;
      column-gap: 0.75rem;
    `}
`
export const Table = styled.div`
  display: grid;
  grid-template-rows: 3.75rem 1fr;
  overflow: hidden;
  max-height: fit-content;
`

export const TableHeader = styled.header`
  width: 100%;
  height: 3.75rem;

  display: grid;
  grid-template-columns: 1fr 1fr 5fr 1fr;

  overflow: hidden;

  border-top: ${({ theme }) => theme.colors.table.border};

  span {
    padding-left: 0.875rem;

    font-family: ${({ theme }) => theme.font.body};
    font-weight: 600;
    font-size: 0.75rem;

    line-height: 3.75rem;

    color: ${({ theme }) => theme.colors.font.secondary};
  }
`

export const TableBody = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 18.125rem);
  overflow-y: auto;
`

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`
