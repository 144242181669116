import styled from 'styled-components'

export const PageLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 5rem 1fr;

  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  box-shadow: ${({ theme }) => theme.colors.shadow};
`

export const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
`
export const Box = styled.div`
  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  border: ${({ theme }) => theme.colors.blockBorder};
  background: ${({ theme }) => theme.colors.blockBackground};
  box-shadow: ${({ theme }) => theme.colors.shadow};

  padding: 1.25rem;

  display: grid;
  grid-template-rows: 1fr;
`

export const Title = styled.h4`
  font-family: ${({ theme }) => theme.font.body};
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.primary};
  margin-bottom: 1rem;
`

export const Text = styled.p`
  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.secondary};
  margin-bottom: 0.5rem;
`

export const BlockContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;

  column-gap: 5rem;
`

export const FormBlock = styled.form`
  display: block;
  margin-bottom: 2rem;
`
