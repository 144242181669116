import styled, { css } from 'styled-components'

interface OrderDetailProps {
  statusId?: number
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 14.375rem);
  overflow: hidden;

  display: grid;
  grid-template-rows: 3.75rem 1fr;

  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  box-shadow: ${({ theme }) => theme.colors.shadow};
`

export const Header = styled.header`
  width: 100%;
  height: 3.75rem;
  padding: 0 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h2`
  margin: 0;

  font-family: ${({ theme }) => theme.font.headings};
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.font.primary};
`

export const Body = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 1rem;
`

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 2rem;
  margin-bottom: 1rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
`

export const Label = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.secondary};
  line-height: 1rem;
  margin-bottom: 0.125rem;
`

export const Value = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.font.body};
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.primary};
  line-height: 1.25rem;
`

export const Status = styled.h4`
  margin: 0;
  padding: 0;

  font-family: ${({ theme }) => theme.font.body};
  font-size: 1rem;
  font-weight: 600;

  text-transform: capitalize;

  ${({ statusId }: OrderDetailProps) => {
    switch (statusId) {
      case 1:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.waiting};
        `
      case 2:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.preparing};
        `
      case 3:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.delivering};
        `
      case 4:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.delivered};
        `
      case 5:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.canceled};
        `
      case 7:
        return css`
          color: ${({ theme }) => theme.colors.orderStates.canceled};
        `
      default:
        return css`
          color: ${({ theme }) => theme.colors.font.primary};
        `
    }
  }}
`

export const OrderItemList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const OrderListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  display: block;
  font-family: ${({ theme }) => theme.font.body};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.primary};
  line-height: 1rem;

  span {
    font-family: ${({ theme }) => theme.font.body};
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.font.primary};
    line-height: 1.25rem;
    margin-right: 1rem;
  }
`

export const ExpandButton = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;

  margin-top: 0.875rem;

  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary.darken};

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
