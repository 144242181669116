import styled, { css } from 'styled-components'

interface ProductListProps {
  isEditing?: boolean
}

export const PageLayout = styled.div`
  display: grid;
  grid-template-rows: 5.75rem 2.25rem 1fr;
`

export const Toolbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`

export const ToolbarRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  > button {
    margin-left: 1rem;
    margin-top: 25px;
  }
`

export const NumberOfItems = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.font.primary};
`

export const Content = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: fit-content;
  overflow-y: hidden;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  ${({ isEditing }: ProductListProps) =>
    isEditing &&
    css`
      grid-template-columns: 1fr 23.25rem;
      column-gap: 0.75rem;
    `}
`

export const Grid = styled.div`
  display: grid;

  max-height: calc(100vh - 17.375rem);
  overflow-x: hidden;
  overflow-y: auto;

  column-gap: 0.75rem;
  row-gap: 0.75rem;

  ${({ isEditing }: ProductListProps) =>
    isEditing
      ? css`
          grid-template-columns: 1fr 1fr 1fr;
        `
      : css`
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        `}

  @media (min-width: 97.5rem) {
    ${({ isEditing }: ProductListProps) =>
      isEditing
        ? css`
            grid-template-columns: 1fr 1fr 1fr 1fr;
          `
        : css`
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          `}
  }
`
