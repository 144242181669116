import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Point, Area } from 'react-easy-crop/types'
import getCroppedImage from '../../../utils/image'
import { Button, RangeInput } from '..'

import { Overlay, CropperContainer, RangeContainer } from './cropper-styles'

interface CropperProps {
  image: string | ArrayBuffer
  onCrop: (file: string) => void
}

export const ImageCropper: React.FC<CropperProps> = ({ image, onCrop }) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<number>(1)
  const [croppedArea, setCroppedArea] = useState<Area | null>(null)
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedArea(croppedAreaPixels)
    },
    []
  )

  async function handleCropImage() {
    if (croppedArea) {
      const croppedImage = await getCroppedImage(image, croppedArea)
      onCrop(croppedImage)
    }
  }

  return (
    <>
      <Overlay />
      <CropperContainer>
        <div>
          <Cropper
            image={typeof image === 'string' ? image : image.toString()}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <RangeContainer>
          <h3>Zoom</h3>
          <RangeInput
            min={1}
            max={3}
            step={0.01}
            onChange={value => setZoom(value)}
            value={zoom}
          />
          <Button type="button" primary onClick={handleCropImage}>
            Capturar
          </Button>
        </RangeContainer>
      </CropperContainer>
    </>
  )
}
