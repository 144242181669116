import styled, { css } from 'styled-components'

interface ButtonProps {
  primary?: boolean
  iconOnly?: boolean
}

export const StyledButton = styled.button`
  appearance: none;
  border: none;

  width: fit-content;
  height: ${({ theme }) => theme.buttons.height};
  padding: ${({ theme }) => theme.buttons.padding};
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${({ theme }) => theme.buttons.radius};
  font-size: ${({ theme }) => theme.buttons.fontSize};

  cursor: pointer;

  > svg {
    fill: ${({ theme }) => theme.colors.button.secondaryFont};
    margin-right: 0.5rem;
  }

  span {
    font-size: 1.25rem;
  }

  ${({ iconOnly }: ButtonProps) =>
    iconOnly &&
    css`
      width: ${({ theme }) => theme.buttons.height};
      height: ${({ theme }) => theme.buttons.height};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      > svg {
        margin-right: 0;
      }
    `}

  background: ${({ theme }) => theme.colors.button.secondaryBackground};
  color: ${({ theme }) => theme.colors.button.secondaryFont};

  &:hover {
    background: ${({ theme }) => theme.colors.button.secondaryBackgroundHover};
  }

  &:active {
    background: ${({ theme }) => theme.colors.button.secondaryBackgroundActive};
  }

  &:disabled {
    background: ${({ theme }) =>
      theme.colors.button.secondaryBackgroundDisabled};
    color: ${({ theme }) => theme.colors.button.secondaryFontDisabled};

    > svg {
      fill: ${({ theme }) => theme.colors.button.secondaryFont};
    }
  }

  ${({ primary }: ButtonProps) =>
    primary &&
    css`
      background: ${({ theme }) => theme.colors.primary.base};
      color: ${({ theme }) => theme.colors.button.primaryFont};

      > svg {
        fill: ${({ theme }) => theme.colors.button.primaryFont};
      }

      &:hover {
        background: ${({ theme }) => theme.colors.primary.darken};
      }

      &:active {
        background: ${({ theme }) => theme.colors.primary.lighten};
      }

      &:disabled {
        background: ${({ theme }) =>
          theme.colors.button.primaryBackgroundDisabled};
        color: ${({ theme }) => theme.colors.button.primaryFontDisabled};

        svg {
          fill: ${({ theme }) => theme.colors.button.primaryFontDisabled};
        }
      }
    `}

  transition: all 0.3s ease-in-out;
`

export const StyledLink = styled.a`
  appearance: none;
  display: block;
  width: fit-content;
  height: ${({ theme }) => theme.buttons.height};
  line-height: ${({ theme }) => theme.buttons.height};
  padding: ${({ theme }) => theme.buttons.padding};
  border-radius: ${({ theme }) => theme.buttons.radius};
  font-size: ${({ theme }) => theme.buttons.fontSize};

  background: ${({ theme }) => theme.colors.button.secondaryBackground};
  color: ${({ theme }) => theme.colors.button.secondaryFont};

  &:hover {
    background: ${({ theme }) => theme.colors.button.secondaryBackgroundHover};
  }

  &:active {
    background: ${({ theme }) => theme.colors.button.secondaryBackgroundActive};
  }

  &:disabled {
    background: ${({ theme }) =>
      theme.colors.button.secondaryBackgroundDisabled};
    color: ${({ theme }) => theme.colors.button.secondaryFontDisabled};
  }

  ${({ primary }: ButtonProps) =>
    primary &&
    css`
      background: ${({ theme }) => theme.colors.primary.base};
      color: ${({ theme }) => theme.colors.button.primaryFont};

      &:hover {
        background: ${({ theme }) => theme.colors.primary.darken};
      }

      &:active {
        background: ${({ theme }) => theme.colors.primary.lighten};
      }

      &:disabled {
        background: ${({ theme }) =>
          theme.colors.button.primaryBackgroundDisabled};
        color: ${({ theme }) => theme.colors.button.primaryFontDisabled};
      }
    `}

  transition: all 0.3s ease-in-out;
`
