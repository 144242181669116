import React from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'

import { Text, Username, Label } from './styles'

interface ModificationProps {
  name: string
  lastName: string
  date: string
}

export const LastModification: React.FC<ModificationProps> = ({
  name,
  lastName,
  date
}) => {
  return (
    <div>
      <Label>Última modificação:</Label>
      <Text>
        {moment(date).format('LLL')} por{' '}
        <Username>{`${name} ${lastName}`}</Username>
      </Text>
    </div>
  )
}
