import React, { ChangeEvent } from 'react'
import { maskCurrency } from '../../../utils/masks'

import {
  Container,
  CurrencyTag,
  ErrorMessage,
  FieldFocus,
  FieldWrapper,
  Label
} from './styles'

import { ReactComponent as Warning } from './assets/warning.svg'

interface FieldProps {
  label: string
  name: string
  value: string
  onChange: (value: string) => void
  error?: string
}

export const CurrencyField: React.FC<FieldProps> = ({
  label,
  name,
  value,
  onChange,
  error,
  ...rest
}) => {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = maskCurrency(event.target.value)
    onChange(value)
  }

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <FieldWrapper hasError={!!error}>
        <input
          type="text"
          name={name}
          value={value}
          onChange={handleChange}
          {...rest}
        />
        <FieldFocus />
        {!!error && <Warning />}
        <CurrencyTag>R$</CurrencyTag>
      </FieldWrapper>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
