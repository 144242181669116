import React from 'react'

import { Title, FormBlock } from '../../pages/Config/styles'
import { ToggleTheme } from '..'

export const ToggleThemeForm: React.FC = () => {
  return (
    <FormBlock noValidate>
      <Title>Modo escuro</Title>
      <ToggleTheme />
    </FormBlock>
  )
}
