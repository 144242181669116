import styled from 'styled-components'

interface ToggleProps {
  isDark?: boolean
}

export const Container = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  outline: none;

  cursor: pointer;

  width: 5.625rem;
  height: 3rem;

  border-radius: 5px;

  position: relative;
  background-color: ${({ theme }) => theme.colors.themeChanger.track};

  transition: all 0.3s ease-in-out;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    transition: all 0.3s ease-in-out;

    &:nth-of-type(1) {
      fill: ${({ theme }) => theme.colors.themeChanger.moon};
      left: 0.875rem;
    }

    &:nth-of-type(2) {
      fill: ${({ theme }) => theme.colors.themeChanger.sun};
      right: 0.875rem;
    }
  }
`

export const Thumb = styled.div`
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 5px;

  position: absolute;

  top: 3px;
  left: ${({ isDark }: ToggleProps) => (isDark ? '3px' : '45px')};

  background-color: ${({ theme }) => theme.colors.themeChanger.thumb};

  transition: all 0.3s ease-in-out;
`
