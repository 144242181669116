import styled, { css } from 'styled-components'

interface Props {
  isLoading?: boolean
}

export const Container = styled.div`
  grid-area: logger;
  padding: 0.75rem;
  background: ${({ theme }) => theme.colors.blockBackground};
  border: ${({ theme }) => theme.colors.blockBorder};
  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  overflow: hidden;

  ${({ isLoading }: Props) =>
    isLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`

export const Title = styled.h3`
  font-family: 'Encode Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.primary};
  margin: 0;
  margin-bottom: 0.75rem;
`

export const List = styled.div`
  width: 100%;
  height: calc(100% - 2.1875rem);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const ItemContainer = styled.div`
  margin-bottom: 0.75rem;
  padding: 0.5rem 0.75rem;
  border-left: solid 3px ${({ theme }) => theme.colors.logger.border};
`

export const ItemText = styled.p`
  margin: 0;
  font-family: 'Encode Sans';
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.logger.text};
  line-height: 1.3;
`

export const Username = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.logger.username};
`

export const Time = styled.p`
  margin: 0;
  margin-top: 0.25rem;
  font-family: 'Encode Sans';
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.secondary};
`
