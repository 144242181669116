import React from 'react'
import { NotificationButton } from '../NotificationButton/NotificationButton'
import { Profile } from '../Profile/Profile'
import { Container, Title, Toolbar } from './styles'

interface HeaderProps {
  title: string
}

export const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Toolbar>
        <NotificationButton />
        <Profile />
      </Toolbar>
    </Container>
  )
}
