import styled from 'styled-components'

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.inputs.height};

  input {
    appearance: none;
    border: none;

    position: relative;

    width: 100%;
    height: 100%;
    padding: ${({ theme }) => theme.inputs.padding};

    flex-shrink: 0;

    border: ${({ theme }) => theme.inputs.border};
    border-radius: ${({ theme }) => theme.inputs.borderRadius};

    background: ${({ theme }) => theme.colors.input.background};
    border-color: ${({ theme }) => theme.colors.input.border};
    color: ${({ theme }) => theme.colors.input.value};

    font-size: 0.875rem;
    font-weight: 400;

    z-index: 1;

    transition: all 0.3s ease-in-out;

    &:focus {
      border-color: ${({ theme }) => theme.colors.input.borderFocus};
    }

    &:focus + div {
      opacity: 1;
      transform: scale(1.01, 1.1);
    }
  }
`

export const Row = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0.5rem;
`

export const TextValue = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font.primary};
`

export const Dropdown = styled.div`
  position: absolute;
  top: 2.75rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.tooltip.background};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`

export const DropdownItem = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  height: ${({ theme }) => theme.inputs.height};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8775rem;
  color: ${({ theme }) => theme.colors.tooltip.label};
  overflow: hidden;
  cursor: pointer;
`

export const Overlay = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: ${({ theme }) => theme.colors.overlay};

  z-index: 10000;
`

export const DialogBox = styled.div`
  position: fixed;

  width: 100%;
  max-width: 25rem;
  height: fit-content;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  padding: 1.125rem;

  border-radius: ${({ theme }) => theme.inputs.borderRadius};

  border: ${({ theme }) => theme.colors.blockBorder};
  background: ${({ theme }) => theme.colors.blockBackground};
  box-shadow: ${({ theme }) => theme.colors.shadow};

  z-index: 10000;

  button {
    &:first-of-type {
      position: absolute;
      right: 1.125rem;
      top: 1.125rem;
    }
    &:last-of-type {
      margin-top: 0.75rem;
    }
  }
`
