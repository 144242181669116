/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useEffect } from 'react'
import { light, dark } from '../styles/themes'

const STORAGE_KEY = '@manie-delivery:dark-theme'

function useTheme(initialState: any) {
  const [state, setState] = useState(() => {
    const storageValue = localStorage.getItem(STORAGE_KEY)
    if (storageValue) {
      const darkTheme = JSON.parse(storageValue)
      return darkTheme ? dark : light
    } else {
      return initialState
    }
  })

  function updateLocalStorage() {
    const darkTheme = JSON.stringify(state.title === 'dark' ? 1 : 0)
    localStorage.setItem(STORAGE_KEY, darkTheme)
  }

  useEffect(() => {
    updateLocalStorage()

    // eslint-disable-next-line
  }, [state])

  return [state, setState]
}

export default useTheme
