import React from 'react'
import { Button, TextField } from '../../../../common/components'
import { NewUserInterface } from '../../../../hooks/useGetUsers'

import { useFormik } from 'formik'
import * as yup from 'yup'

import {
  Overlay,
  DialogBox,
  DialogFooter,
  Title,
  DialogContent
} from './styles'

interface NewUserProps {
  open: boolean
  onCancel: () => void
  onConfirm: (user: NewUserInterface) => void
  onUsernameCreate: (username: string) => boolean
}

export const NewUser: React.FC<NewUserProps> = ({
  open,
  onCancel,
  onConfirm,
  onUsernameCreate
}) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    confirmPassword: ''
  }

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Esse campo é obrigatório').trim(),
    lastName: yup.string().required('Esse campo é obrigatório').trim(),
    username: yup
      .string()
      .required('Esse campo é obrigatório')
      .trim()
      .test(
        'exists',
        'O nome de usuário já existe',
        val => !onUsernameCreate(val as string)
      ),
    password: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Esse campo deve conter pelo menos 8 (oito) dígitos'),
    confirmPassword: yup
      .string()
      .required('É necessário confirmar a senha')
      .oneOf(
        [yup.ref('password'), null],
        'Os campos nova senha e confirmar nova senha devem ser iguais'
      )
  })

  const { errors, touched, isValid, handleSubmit, getFieldProps } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: formValues => {
      const payload = {
        name: formValues.firstName,
        lastName: formValues.lastName,
        user: formValues.username,
        password: formValues.password,
        adm: 0,
        darkMode: 0,
        active: 0
      }
      onConfirm(payload)
      onCancel()
    }
  })

  if (!open) return null

  return (
    <>
      <Overlay />
      <DialogBox>
        <Title>Novo usuário</Title>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              label="Nome"
              type="text"
              {...getFieldProps('firstName')}
              error={
                errors.firstName && touched.firstName ? errors.firstName : ''
              }
            />
            <TextField
              label="Sobrenome"
              type="text"
              {...getFieldProps('lastName')}
              error={errors.lastName && touched.lastName ? errors.lastName : ''}
              disableAutoComplete
            />
            <TextField
              label="Nome de usuário"
              type="text"
              {...getFieldProps('username')}
              error={errors.username && touched.username ? errors.username : ''}
              disableAutoComplete
            />
            <TextField
              label="Nova senha"
              type="password"
              {...getFieldProps('password')}
              error={errors.password && touched.password ? errors.password : ''}
              disableAutoComplete
            />
            <TextField
              label="Confirmar nova senha"
              type="password"
              {...getFieldProps('confirmPassword')}
              error={
                errors.confirmPassword && touched.confirmPassword
                  ? errors.confirmPassword
                  : ''
              }
              disableAutoComplete
            />
          </DialogContent>
          <DialogFooter>
            <Button type="button" onClick={onCancel}>
              Cancelar
            </Button>
            <Button type="submit" primary disabled={!isValid}>
              Criar novo usuário
            </Button>
          </DialogFooter>
        </form>
      </DialogBox>
    </>
  )
}
