import styled from 'styled-components'

export const Text = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font.secondary};
`
export const Username = styled.span`
  font-weight: 500;
`
export const Label = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.font.primary};
  margin-bottom: 0;
`
