import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: ${({ theme }) => theme.colors.overlay};

  z-index: 10000;
`

export const DialogBox = styled.div`
  position: fixed;

  width: 100%;
  max-width: 27rem;
  height: fit-content;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  padding: 1.125rem;

  border-radius: ${({ theme }) => theme.inputs.borderRadius};

  border: ${({ theme }) => theme.colors.blockBorder};
  background: ${({ theme }) => theme.colors.blockBackground};
  box-shadow: ${({ theme }) => theme.colors.shadow};

  z-index: 10000;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  max-width: 25rem;

  padding-bottom: 1rem;
`

export const DialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
  padding-top: 1rem;

  border-top: solid 0.0625rem rgba(125, 125, 125, 0.05);
`

export const Title = styled.h4`
  margin: 0;
  font-family: ${({ theme }) => theme.font.headings};
  font-weight: 400;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.font.primary};
  margin-bottom: 1.25rem;
`

export const Text = styled.p`
  font-family: ${({ theme }) => theme.font.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font.secondary};
  margin-bottom: 1.25rem;
`
