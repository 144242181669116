import React from 'react'
import { Check } from './Icons'
import {
  LoadingContainer,
  LoadingSpinner,
  Overlay,
  SuccessContainer,
  ErrorContainer
} from './styles'

interface ToastProps {
  lightbox?: boolean
  timed?: boolean
  success?: boolean
  danger?: boolean
  loading?: boolean
  title?: string
  text?: string
  buttonText?: string
  onConfirm?: () => void
}

export const Toast: React.FC<ToastProps> = ({
  lightbox,
  timed,
  success,
  danger,
  loading = false,
  title,
  text,
  buttonText = 'Fechar',
  onConfirm
}) => {
  if (loading) {
    return (
      <>
        {lightbox && <Overlay />}
        <LoadingContainer lightbox={lightbox}>
          <LoadingSpinner></LoadingSpinner>
          <p>{text || 'Carregando'}</p>
        </LoadingContainer>
      </>
    )
  }

  if (success) {
    return (
      <>
        {lightbox && <Overlay />}
        <SuccessContainer lightbox={lightbox}>
          <div>
            <Check />
            <p>{text}</p>
          </div>
          <button type="button" onClick={onConfirm}>
            Fechar
          </button>
        </SuccessContainer>
      </>
    )
  }

  if (danger) {
    return (
      <>
        {lightbox && <Overlay />}
        <ErrorContainer lightbox={lightbox}>
          <div>
            <Check />
            <p>{text}</p>
          </div>
          <button type="button" onClick={onConfirm}>
            {buttonText}
          </button>
        </ErrorContainer>
      </>
    )
  }

  return <div></div>
}
