import styled, { css, keyframes } from 'styled-components'

interface ToastProps {
  lightbox?: boolean
}

const spin = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg)}
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.overlay};
  z-index: 10000;
`

export const LoadingContainer = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;
  color: ${({ theme }) => theme.colors.font.primary};

  ${({ lightbox }: ToastProps) =>
    lightbox &&
    css`
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10000;
    `};
`

export const LoadingSpinner = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  border: solid 0.125rem #e8c221;
  border-bottom: solid 0.125rem transparent;

  animation: ${spin} 1.2s ease-in-out infinite;
`

export const SuccessContainer = styled.div`
  width: 100%;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  padding: 1rem;
  background-color: #839f0b;
  color: #fff;

  ${({ lightbox }: ToastProps) =>
    lightbox &&
    css`
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10000;
    `}

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
  }

  button {
    appearance: none;
    border: none;
    background: rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 0.875rem;
    padding: 0.5rem 1.125rem;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
  }
`

export const ErrorContainer = styled.div`
  width: 100%;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  padding: 1rem;
  background-color: #f7826d;
  color: #fff;

  ${({ lightbox }: ToastProps) =>
    lightbox &&
    css`
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10000;
    `}

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
  }

  button {
    appearance: none;
    border: none;
    background: rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 0.875rem;
    padding: 0.5rem 1.125rem;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
  }
`
