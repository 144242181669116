import styled, { css } from 'styled-components'

interface UploaderProps {
  imageSelected?: boolean
  isDragActive?: boolean
  isDragReject?: boolean
}

export const UploadContainer = styled.div`
  padding: 2.5rem 1.5rem;
  border: dashed 3px ${({ theme }) => theme.colors.uploader.border};
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;

  ${({ isDragActive }: UploaderProps) =>
    isDragActive &&
    css`
      border-color: ${({ theme }) => theme.colors.uploader.borderAccepted};
      p {
        color: #95e58c;
      }
    `}

  ${({ imageSelected }: UploaderProps) =>
    imageSelected &&
    css`
      visibility: hidden;
      position: absolute;
    `}
`

export const ImageContainer = styled.div`
  overflow: hidden;
  position: relative;

  ${({ imageSelected }: UploaderProps) =>
    !imageSelected &&
    css`
      visibility: hidden;
      position: absolute;
    `}

  img {
    border-radius: ${({ theme }) => theme.inputs.borderRadius};
    width: 100%;
  }

  div {
    display: flex;
    column-gap: 1rem;
    padding: 1rem 0;
  }
`

export const UploadText = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.font.secondary};

  span {
    color: ${({ theme }) => theme.colors.uploader.activeLink};
  }
`
