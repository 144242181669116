import { useEffect, useState } from 'react'
import { api } from '../api/api'
import { ConfigInterface } from '../api/interfaces'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null
}

interface StateInterface {
  data: ConfigInterface | null
  loading: boolean
  error: string | null
}
interface Hook {
  data: ConfigInterface | null
  loading: boolean
  error: string | null
  refetch: () => Promise<void>
  handleChangeDeliveryTime: (timeMin: number, timeMax: number) => Promise<void>
  toggleStore: (isOpen: boolean) => Promise<void>
  handleChangeRadius: (radius: number) => Promise<void>
  handleChangeAddress: (
    address: string,
    lat: number,
    lng: number
  ) => Promise<void>
}

export const useGetConfig = (): Hook => {
  const [state, setState] = useState<StateInterface>(INITIAL_STATE)

  const query = async () => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const { data } = await api.get('/config', {
        headers: { 'content-type': 'application/json' }
      })
      setState({ data: data.config[0], loading: false, error: null })
    } catch (err) {
      setState(current => ({
        ...current,
        loading: false,
        error: 'Ocorreu um erro ao buscar os dados da loja'
      }))
    }
  }

  const handleChangeDeliveryTime = async (timeMin: number, timeMax: number) => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const user = localStorage.getItem('@manie-delivery:user')
      if (user) {
        const { idUser } = JSON.parse(user)
        const payload = JSON.stringify({
          idConfig: state?.data?.idConfig,
          idUser,
          timeOrderMin: timeMin,
          timeOrderMax: timeMax
        })
        await api.post('/config/edit', payload)
        query()
        setState(current => ({ ...current, loading: false, error: null }))
      }
    } catch (e) {
      setState(current => ({
        ...current,
        loading: false,
        error: 'Ocorreu um erro ao trocar o tempo de entrega'
      }))
    }
  }

  const toggleStore = async (isOpen: boolean) => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const user = localStorage.getItem('@manie-delivery:user')
      if (user) {
        const { idUser } = JSON.parse(user)
        const payload = JSON.stringify({
          idConfig: state?.data?.idConfig,
          idUser,
          isOpen: isOpen ? 0 : 1
        })
        await api.post('/config/edit', payload)
        query()
        setState(current => ({ ...current, loading: false, error: null }))
      }
    } catch (e) {
      setState(current => ({
        ...current,
        loading: false,
        error: 'Ocorre um erro ao ativar/desativar a loja'
      }))
    }
  }

  const handleChangeRadius = async (radius: number) => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const user = localStorage.getItem('@manie-delivery:user')
      if (user) {
        const { idUser } = JSON.parse(user)
        const payload = JSON.stringify({
          idConfig: state?.data?.idConfig,
          idUser,
          orderRange: radius
        })
        await api.post('/config/edit', payload)
        query()
        setState(current => ({ ...current, loading: false, error: null }))
      }
    } catch (e) {
      setState(current => ({
        ...current,
        loading: false,
        error: 'Ocorreu um erro ao alterar o raio de entrega'
      }))
    }
  }

  const handleChangeAddress = async (
    address: string,
    lat: number,
    lng: number
  ) => {
    setState(current => ({ ...current, loading: true, error: null }))
    try {
      const user = localStorage.getItem('@manie-delivery:user')
      if (user) {
        const { idUser } = JSON.parse(user)
        const payload = JSON.stringify({
          idConfig: state?.data?.idConfig,
          idUser,
          address,
          lat,
          lng
        })
        await api.post('/config/edit', payload)
        query()
        setState(current => ({ ...current, loading: false, error: null }))
      }
    } catch (e) {
      setState(current => ({
        ...current,
        loading: false,
        error: 'Ocorreu um erro ao trocar o endereço da loja'
      }))
    }
  }

  useEffect(() => {
    query()
  }, [])

  return {
    ...state,
    refetch: query,
    handleChangeDeliveryTime,
    toggleStore,
    handleChangeRadius,
    handleChangeAddress
  }
}
