import React from 'react'
import { Container, LogoContainer, LogoutButton } from './styles'

import { Menu } from '../Menu/Menu'
import { ReactComponent as LogoutIcon } from './assets/power.svg'

import logo from './assets/logo.png'
import { useAuth } from '../../hooks/useAuth'

interface AsideProps {
  currentPage: string
}

export const Aside: React.FC<AsideProps> = ({ currentPage }) => {
  const { signOut } = useAuth()

  return (
    <Container>
      <LogoContainer>
        <img src={logo} alt="Manie Delivery" />
      </LogoContainer>
      <Menu currentPath={currentPage} />
      <LogoutButton onClick={signOut}>
        <LogoutIcon />
        <span>Sair</span>
      </LogoutButton>
    </Container>
  )
}
