import React, { createContext, useState } from 'react'
import { CategoryInterface } from '../api/interfaces'

interface ProductContextProps {
  children: JSX.Element | JSX.Element[]
}

interface AddProductInterface {
  step1: {
    name: string
    desc: string
    price: string
    amount: number
  }
  step2: {
    categories: CategoryInterface[]
  }
  step3: {
    image: string
  }
}
interface ProductProviderInterface {
  value: AddProductInterface
  setValue: React.Dispatch<React.SetStateAction<AddProductInterface>>
  resetProductCreation: () => void
}

const initialValue: AddProductInterface = {
  step1: {
    name: '',
    desc: '',
    price: '0.00',
    amount: 0
  },
  step2: {
    categories: []
  },
  step3: {
    image: ''
  }
}

const NewProductContext = createContext({} as ProductProviderInterface)

const AddProductProvider: React.FC<ProductContextProps> = ({ children }) => {
  const [value, setValue] = useState<AddProductInterface>(initialValue)

  const resetProductCreation = () => {
    setValue(initialValue)
  }

  return (
    <NewProductContext.Provider
      value={{ value, setValue, resetProductCreation }}
    >
      {children}
    </NewProductContext.Provider>
  )
}

export { NewProductContext, AddProductProvider }
