import styled, { css } from 'styled-components'

interface Props {
  hasError?: boolean
  selected?: boolean
  open?: boolean
}

export const Container = styled.div`
  width: 100%;
  max-width: 8rem;
  display: flex;
  flex-direction: column;
  margin: 0;
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.inputs.labelFontSize};
  font-weight: ${({ theme }) => theme.inputs.labelFontWeight};
  color: ${({ theme }) => theme.colors.input.label};
  margin-bottom: 0.5rem;
`

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.inputs.height};

  display: flex;
  justify-content: center;
  align-items: center;

  input {
    appearance: none;
    border: none;

    position: relative;

    width: 100%;
    height: 100%;
    padding: ${({ theme }) => theme.inputs.padding};
    padding-right: 2rem;

    border: ${({ theme }) => theme.inputs.border};
    border-radius: ${({ theme }) => theme.inputs.borderRadius};

    background: ${({ theme }) => theme.colors.input.background};
    border-color: ${({ theme }) => theme.colors.input.border};
    color: ${({ theme }) => theme.colors.input.value};

    font-size: 0.875rem;
    font-weight: 400;

    z-index: 1;

    transition: all 0.3s ease-in-out;

    &:focus {
      border-color: ${({ theme }) => theme.colors.input.borderFocus};
    }

    &:focus + div {
      opacity: 1;
      transform: scale(1.01, 1.1);
    }
  }

  ${({ hasError }: Props) =>
    hasError &&
    css`
      input {
        border-color: ${({ theme }) => theme.colors.error};

        &:focus {
          border-color: ${({ theme }) => theme.colors.error};
        }

        &:focus + div {
          opacity: 0;
          transform: scale(1, 1);
        }
      }
    `}
`

export const FieldFocus = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;

  border-radius: ${({ theme }) => theme.inputs.borderRadius};
  background: ${({ theme }) => theme.colors.input.outlineFocus};

  transition: all 0.3s ease-in-out;
  z-index: 0;
`

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.error};
`

export const NumberContainer = styled.div`
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;

  border-top-right-radius: ${({ theme }) => theme.inputs.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.inputs.borderRadius};

  width: 1.625rem;
  display: flex;
  flex-direction: column;
  z-index: 1;

  background-color: ${({ theme }) => theme.colors.input.arrowTrack};

  button {
    display: flex;
    flex: 1;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: ${({ theme }) => theme.colors.input.arrows};
    }
  }
`
