import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Toast } from '../../../../common/components'
import { PageContainer } from '../../../../layout'
import { ProductCard, ProgressSteps } from '../../components'

import { NewProductContext } from '../../../../context/AddProductContext'

import { Content, Box, UpperContent, Footer, Title } from './styles'
import { useCreateProduct } from '../../../../hooks/useCreateProduct'
import { currencyToNumber } from '../../../../utils/format'

const AddProductFourthStep: React.FC = () => {
  const history = useHistory()
  const { success, loading, error, mutation } = useCreateProduct()
  const { value } = useContext(NewProductContext)

  useEffect(() => {
    if (!value.step3.image) {
      history.push('/product/new/step3')
    }
  }, [])

  const goBack = () => {
    history.push('/product/new/step3')
  }

  const navigateToProductList = () => {
    history.push('/product/list')
  }

  const handleSubmit = () => {
    const body = {
      name: value.step1.name,
      desc: value.step1.desc,
      price: currencyToNumber(value.step1.price),
      amount: value.step1.amount,
      categories: value.step2.categories,
      image: value.step3.image.split('base64,')[1]
    }

    mutation(body)
  }

  return (
    <>
      <PageContainer title="Adicionar produto" urlGroup="products">
        <Content>
          <Box>
            <UpperContent>
              <ProgressSteps currentStep={4} numberOfSteps={4} />
              <Title>Confirma a adição do produto?</Title>
              <ProductCard
                name={value.step1.name}
                price={currencyToNumber(value.step1.price)}
                image={value.step3.image}
              />
            </UpperContent>
            <Footer>
              <Button type="button" onClick={goBack}>
                Voltar
              </Button>
              <Button
                type="button"
                primary
                disabled={loading}
                onClick={handleSubmit}
              >
                Concluir
              </Button>
            </Footer>
          </Box>
        </Content>
      </PageContainer>
      {loading && <Toast lightbox loading />}
      {!!success && (
        <Toast
          lightbox
          success
          text={success}
          onConfirm={navigateToProductList}
        />
      )}
      {!!error && (
        <Toast
          lightbox
          danger
          text="Ocorreu um erro e produto não pode ser adicionado. Por favor tente mais tarde"
        />
      )}
    </>
  )
}

export default AddProductFourthStep
