import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-bottom: 0.875rem;
`

export const Label = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #32373b;
`

export const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  height: 3rem;
  border-radius: 5px;

  background-color: #f4f5f8;
  border-bottom: solid 2px #e6e8f0;

  input {
    appearance: none;
    border: none;
    background-color: transparent;

    width: 100%;
    height: 100%;
    padding-left: 1rem;
    padding-right: 2.5rem;
    font-size: 0.875rem;
    color: #32373b;
  }
`

export const Field = styled.input`
  appearance: none;
  border: none;
  width: 100%;
  height: 3rem;

  border-radius: 5px;
  background-color: #f4f5f8;
  border-bottom: solid 2px #e6e8f0;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.875rem;
  color: #32373b;
  margin-bottom: 0.5rem;
`

export const ToggleButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
  color: #c71d1d;
  margin-bottom: 0.5rem;
`
