import styled from 'styled-components'

export const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
`
export const Box = styled.div`
  border-radius: ${({ theme }) => theme.inputs.borderRadius};

  border: ${({ theme }) => theme.colors.blockBorder};
  background: ${({ theme }) => theme.colors.blockBackground};
  box-shadow: ${({ theme }) => theme.colors.shadow};

  display: grid;
  grid-template-rows: 1fr 5rem;
`

export const UpperContent = styled.div`
  padding: 1.25rem;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 1.25rem;

  border-top: solid 1px rgba(123, 123, 123, 0.15);
`

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: 1.125rem;
  font-weight: 500;

  margin: 2rem 0 1.5rem;

  color: ${({ theme }) => theme.colors.font.primary};
`

export const Form = styled.form`
  width: 100%;
  max-width: 21.25rem;
`
