import React from 'react'

import { Container, Step } from './styles'

interface StepProps {
  currentStep: number
  numberOfSteps: number
}

interface StepInterface {
  id: string
  content: number
}

const renderSteps = (numItems: number) => {
  return Array.from({ length: numItems }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: k + 1
  }))
}

export const ProgressSteps: React.FC<StepProps> = ({
  currentStep,
  numberOfSteps
}) => {
  const list: StepInterface[] = renderSteps(numberOfSteps)
  return (
    <Container>
      {list.map(item => (
        <Step
          currentlySelected={currentStep === item.content}
          finished={item.content < currentStep}
          key={item.id}
        >
          {item.content}
        </Step>
      ))}
    </Container>
  )
}
